<template>
  <div class="wrap">
    <nav-ber :title="'智慧印章解决方案'"></nav-ber>
    <main>
      <div class="top sumBox">
        <h2 class="cTil"><em>项目质量管理</em></h2>
        <h5><em>01</em>全过程数据化落实监管需求</h5>
        <p>大型建筑施工企业通常需要跨地区经营，设立的分支机构多、工程项目多，导致了企业印章的种类和数量繁多。</p>
        <h5><em>02</em>全过程数据化落实监管需求</h5>  
        <p>项目施工周期长、人员流动性大，实施过程中各类印章的使用频率很高，容易违规用印，难以监管使用情况，一旦出事难以及时追溯责任人。</p>
        <h5><em>03</em>异地签约、招投标需要公章出差</h5>
        <p>异地招投标、异地签约等情况需要飞行用印、公章出差，印章存在丢失、盗用风险。</p>
        <h5><em>04</em>合同关系复杂、表见代理风险大</h5>
        <p>工程行业合同涉及金额较高，主体与法律关系十分复杂，表见代理认定纠纷多，可能给公司造成重大经济损失。</p>
      </div>
      <div class="content">
        <section class="serve">
          <div class="sumBox">
            <h2 class="cTil"><em>方案优势</em></h2>
            <h5><em>01</em>众多建筑企业运行良好</h5>
            <p>中国建筑、中国铁建、中国交建、中电建路桥、中国中铁等众多特级建筑企业已上线智能印章管理系统，全面升级印章智慧管理。</p>
            <h5><em>02</em>全程掌控，降低风险</h5>  
            <p>防范印章违规使用、挪用、偷盖，盖章前中后均有安全技术实时检验印章使用和保管的异常情况智能预警。</p>
            <h5><em>03</em>完善建筑企业信息化建设</h5>
            <p>印章智慧管理平台支持集团多级分权管理，可对接第三方系统如ERP、OA，实现用印全过程管控闭环。</p>
            <div class="img"><img src="~@/assets/images/engineering/sealPic1.jpg" alt="" /></div>
          </div>
          <h2 class="cTil"><em>产品亮点</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/sealPic2.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4><em>01</em>印章全生命周期管理</h4>
                <div class="line"></div>
                <p>针对印章种类多、数量多等问题，帮助建筑企业把每一枚普通印章都装入智能硬件，变为可在线实时管控的智能印章，接入管理平台，实现全集团各分支机构的一体化管理，构建印章智慧管理闭环。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/sealPic3.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4><em>02</em>项目部印章监管</h4>
                <div class="line"></div>
                <p>帮助项目部提高用印效率、确保印章合规使用。总部管理人员可随时在线总览印章保管和使用情况。印章智慧管理平台可与第三方系统对接，一个平台完成审批全部流程，合同无需寄到总部，用印过程实时监控，让集团总部对印章使用了如指掌。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/sealPic4.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4><em>03</em>印章外带远程管控</h4>
                <div class="line"></div>
                <p>确保异地用印按照审批意见严格执行。智能用印时，不授权无法盖章，人章分离，保障安全使用。重要合同可使用远程盖章，领导远程实时审批，授权后自动盖章持章人无权随意使用。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/sealPic5.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4><em>04</em>招投标文件用印管理</h4>
                <div class="line"></div>
                <p>针对招投标文件用印量大、难管控等问题，用印工作台具备红外监控围栏功能需在监控内进行盖章，每次盖章均自动拍摄高清影像资料，同步上传平台，便于回溯。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/sealPic6.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4><em>05</em>加强合同审批、防止篡改</h4>
                <div class="line"></div>
                <p>帮助建筑企业完善审批流程，领导和专业人员可以线上完成审批，方便快捷，提高审核质量。盖章前中后，系统自动对文件进行核对校验，及时发现合同风险。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #f8f8f8;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
      em{
        font-size: .28rem;
        color: #f70000;
        font-family: 'Gilroy-Bold';
        margin-right: .16rem;
      }
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}
.sumBox{
  padding: .6rem .4rem;
  h5{
    font-size: .26rem;
    color: #2d322d;
    line-height: .36rem;
    margin: .2rem 0 .15rem;
    &:first-child{
      padding-top: 0;
    }
    em{
      font-size: .26rem;
      color: #f70000;
      font-family: 'Gilroy-Bold';
      margin-right: .16rem;
    }
  }
  .img{
    margin-top: .4rem;
    overflow: hidden;
    border-radius: var(--border-radius);
  }
}
.serve{
  .sumBox{
    margin: -.6rem -.4rem .6rem;
    border-radius: 0 0 .28rem .28rem;
    background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
    background-size: .4rem;
  }
}
</style>

