<template>
  <div class="wrap">
    <nav-ber title="城市合伙人"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <div class="content">
        <section>
          <h3 class="cTil"><em>城市合伙人定位</em></h3>
          <div class="pBox">
            <p>
              城市合伙人是华建通品牌传播的重要力量，同事也是市场生态的建设者和省份市场深耕及长期持续经营的开拓者。
            </p>
          </div>
        </section>
        <section class="lump2">
          <h3 class="cTil"><em>遴选资格</em></h3>
          <div class="ul">
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/partnerIcon1.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>客户基础</h4>
                <div class="pBox">
                  <p>
                    城市合伙人需拥有一定数量的建筑行业客户或政府资源，协助华建通平台与政府建立良好关系。
                  </p>
                </div>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/partnerIcon2.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>运营达量</h4>
                <div class="pBox">
                  <p>
                    完成专属区域内指定平台运营任务，以确保平台的市场占有率和用户活跃度。
                  </p>
                </div>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/partnerIcon3.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>人员规模</h4>
                <div class="pBox">
                  <p>
                    保证一定数量的专职人员参与平台的推广工作，需通过公司的认证考核，确保具备开展推广工作的能力和素质。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="lump3">
          <h3 class="cTil"><em>合作权益</em></h3>
          <swiper
            :pagination="rightsPagination"
            :modules="rightsModules"
            :loop="true"
            :space-between="20"
            class="cRights"
          >
            <swiper-slide>
              <div class="ico">
                <img
                  src="      /hjt-door/202405/gw/hjt/20240527102539b7b068a3-a64c-4e06-a703-85f2e9731cb0.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h5>独家经营</h5>
                <p>
                  享有独家经营权,成为平台指定区域内唯一合作伙伴，确保当地市场的竞争优势。
                </p>
              </div>
              <em>01</em>
            </swiper-slide>
            <swiper-slide>
              <div class="ico">
                <img
                  src="      /hjt-door/202405/gw/hjt/20240527102539f82189e8-9630-4359-9ab5-b566c981203a.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h5>权益奖励</h5>
                <p>
                  1、政府监管平台项目落地一次性奖励。2、共同运营平台，分享平台产生的收益。具体合作方式可以是设立合资公司共同运营，或根据公司运营盈利情况按约定比例分享收益。
                </p>
              </div>
              <em>02</em>
            </swiper-slide>
            <swiper-slide>
              <div class="ico">
                <img
                  src="      /hjt-door/202405/gw/hjt/20240527102539b493dc98-e4c5-4708-949f-d7ab8078db0d.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h5>运营支持</h5>
                <p>
                  享受一系列全面的运营活动支持，包括区域专属推广会议和活动支持、联谊活动支持、宣传物料支持、重大项目现场支撑服务。
                </p>
              </div>
              <em>03</em>
            </swiper-slide>
            <swiper-slide>
              <div class="ico">
                <img
                  src="      /hjt-door/202405/gw/hjt/20240527102540c95e0045-0ea3-4541-99c6-8eac69d7c6b1.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h5>金融支持</h5>
                <p>
                  享受一系列资金支持与股权激励政策，包括低成本资金支持（贷款支持、专项资金）、股权投资支持、中国东信股权激励支持。
                </p>
              </div>
              <em>04</em>
            </swiper-slide>
            <swiper-slide>
              <div class="ico">
                <img
                  src="      /hjt-door/202405/gw/hjt/2024052710254017f82b41-c5fa-4dc2-98a4-d88b8526619c.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h5>技术支持</h5>
                <p>全方位、全天候的技术支撑VIP专属服务。</p>
              </div>
              <em>05</em>
            </swiper-slide>
          </swiper>
        </section>
        <van-button
          class="cApplyBtn"
          @click="
            $router.push({
              path: '/consultform',
              query: { plateArr: '城市合伙人' },
            })
          "
          >申请办理</van-button
        >
      </div>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);

const modules = [Pagination];
const pagination = {
  clickable: true,
};

const rightsModules = [Pagination];
const rightsPagination = {
  clickable: true,
};

const apiData0 = [
  {
    image: '/hjt-door/202407/gw/hjt/20240717150104da19cc3c-7a7f-4402-874a-a2484e44a0c2.png',
    newsId: 0,
    newsContent: '<h3>城市合伙人</h3><p>双方的紧密合作，能够共同推动华建通平台的快速发展，实现市场份额的快速扩张。!</p><p>愿意提供政府监管平台建设运营商机，并协同华建通将其落地实施，同时积极为华建通平台引入特定规模的使用客户流量，助力华建通在竞争激烈的市场中脱颖而出。</p>'
  }
]

</script>
<style scoped lang="scss">
.content {
  section {
    margin-bottom: 0.6rem;
  }
  .cTil {
    margin-bottom: 0.33rem;
  }
  .pic {
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: 0.03rem solid #c7c7c7;
    margin-top: 0.3rem;
  }
  .cTil {
    text-align: center;
    em:after {
      right: 0;
      margin: 0 auto;
    }
  }
  .pBox {
    h4 {
      font-size: 0.22rem;
      color: #333;
      line-height: 0.36rem;
    }
    p {
      text-indent: 2em;
      margin-bottom: 0.36rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.lump2 {
  .ul {
    .li {
      position: relative;
      padding: 0.27rem 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: center;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      position: absolute;
      left: 0.3rem;
      top: 0.5rem;
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      min-height: 1.2rem;
      margin-left: 1.2rem;
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      p {
        text-indent: 0;
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
    .btn {
      float: left;
      padding: 0 0.2rem;
      height: 0.48rem;
      font-size: 0;
      line-height: 0.48rem;
      border-radius: 0.48rem;
      text-align: center;
      background: #f70000;
      margin-top: 0.2rem;
      em {
        font-size: 0.22rem;
        color: #fff;
        padding-right: 0.2rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.08rem;
      }
    }
  }
}

.cRights {
  .swiper-slide {
    height: 4rem;
    background: #d7000f;
    padding: 0.6rem 0.4rem;
    position: relative;
    transition: all 0.4s;
    border-radius: 0.28rem;
    .ico {
      height: 0.8rem;
      img {
        max-height: 100%;
      }
    }
    .txt {
      margin-top: 0.4rem;
      position: relative;
      z-index: 3;
      h5 {
        color: #fff;
        font-size: 0.28rem;
        line-height: 0.38rem;
        margin-bottom: 0.2rem;
      }
      p {
        color: #fffc;
        font-size: 0.22rem;
        line-height: 0.36rem;
      }
    }
    em {
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), #d7000f);
      -webkit-background-clip: text;
      bottom: 0;
      color: transparent;
      font-family: "Gilroy-Bold";
      font-size: 4rem;
      line-height: 1;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }
  :deep(.swiper-pagination){
    position: static;
    .swiper-pagination-bullet{
      width: .1rem;
      height: .1rem;
      opacity: 1;
      background: #646464;
      margin: 0 .05rem;
      &.swiper-pagination-bullet-active{
        background: #d7000f;
      }
    }
  }
}
</style>

