<template>
  <div class="wrap">
    <nav-ber title="金融服务"></nav-ber>
    <main class="financial">
      <Cbanner :list="apiData0"></Cbanner>
      <section class="hotWrap">
        <div class="til">
          <h3 class="hTil"><em>热门产品</em></h3>
          <!-- <router-link to="/financial/lwgzd" class="more">查看全部</router-link> -->
        </div>
        <div class="li" @click="$router.push('/financial/lwgzd')">
          <div class="row">
            <h4>劳务工资贷</h4>
            <div class="num">已服务5000+客户数</div>
          </div>
          <div class="p">金融机构-鹏金所</div>
          <div class="label">
            <div class="i">年化利率利率12%</div>
            <div class="i">可随时提前还款</div>
            <div class="i">低于市场平均融资利率</div>
          </div>
        </div>
      </section>
      <section class="insurance">
        <div class="til">
          <h3 class="hTil"><em>保险保函产品</em></h3>
          <router-link class="more" to="/financial/insuranceList">查看全部</router-link>
        </div>
        <div class="hd">
          <van-tabs v-model:active="hTilAct" class="ball">
            <van-tab title="工程保险"></van-tab>
            <van-tab title="工程保函"></van-tab>
            <van-tab title="意外伤害险"></van-tab>
          </van-tabs>
        </div>
        <transition name="fadeInUp">
          <div class="bd">
            <div class="ul" v-show="hTilAct === 0">
              <div class="li" v-for="item in bxbhList1" :key="item.newsId">
                <div class="icon">
                  <img :src="item.appImg" alt=""/>
                </div>
                <div class="txt">
                  <div class="row">
                    <h4>{{item.newsTitle}}</h4>
                  </div>
                  <div class="pBox" v-html="item.newsTags"></div>
                </div>
                <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
              </div>
            </div>
            <div class="ul" v-show="hTilAct === 1">
              <div class="li" v-for="item in bxbhList2" :key="item.newsId">
                <div class="icon">
                  <img :src="item.appImg" alt=""/>
                </div>
                <div class="txt">
                  <div class="row">
                    <h4>{{item.newsTitle}}</h4>
                  </div>
                  <div class="pBox" v-html="item.newsTags"></div>
                </div>
                <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
              </div>
            </div>
            <div class="ul" v-show="hTilAct === 2">
              <div class="li" v-for="item in bxbhList3" :key="item.newsId">
                <div class="icon">
                  <img :src="item.appImg" alt=""/>
                </div>
                <div class="txt">
                  <div class="row">
                    <h4>{{item.newsTitle}}</h4>
                  </div>
                  <div class="pBox" v-html="item.newsTags"></div>
                </div>
                <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
              </div>
            </div>
          </div>
        </transition>
      </section>
      <section class="creditWrap">
        <div class="til">
          <h3 class="hTil"><em>精选融资信贷服务</em></h3>
          <router-link class="more" to="/financial/creditList">查看全部</router-link>
        </div>
        <div class="bd">
          <div v-for="item in bxbhList4" :key="item.newsId" @click="$router.push({'path': '/financial/creditData', query: {'newsId': item.newsId}})" class="item">
            <div class="logo">
              <img :src="$store.state.localHttp + item.image" alt="" />
            </div>
            <div class="name">{{item.newsTitle}}<em>{{item.phone}}</em></div>
            <div class="ul" v-html="item.newsTags"></div>
            <div class="labels">
              <div class="it" v-for="lab in item.newsAuthor.split('|')" :key="lab">{{lab}}</div>
            </div>
          </div>

          <!-- <div @click="skipOutsideChain('https://gjjf.gxcic.net/azx-mobile/lwjr?type=28&insurerName=鹏金所')" class="item last">
            <div class="logo">
              <img src="~@/assets/images/financial/bankLogoPjs.png" alt="" />
            </div>
            <div class="name">劳务工资贷</div>
            <p>单笔借支额度：每个工人单笔贷款额度<br>
              不超5000（每月一次）</p>
            <p>借款要求：劳务公司质押产值单、合同等相关资料，项目有使用一号工班劳务管理系统</p>
            <p>已服务客户数:5000+</p>
          </div> -->
        </div>
      </section>
      <section class="solution">
        <div class="til">
          <h3 class="hTil"><em>金融解决方案</em></h3>
        </div>
        <ul>
          <li class="li1" @click="$router.push('/financial/payment')">
            <h4>工资代发服务</h4>
            <div class="btn">去看看</div>
          </li>
          <li class="li2" @click="$router.push('/financial/greenfinance')">
            <h4>绿建金融</h4>
            <div class="btn">去看看</div>
          </li>
        </ul>
      </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import Cbanner from "@/components/Cbanner.vue";
import { getInfo, getFinancialList } from '@/api/http';
import { skipOutsideChain } from '@/api/public'
import { ref, watch } from 'vue';
const hTilAct = ref(0);
// const tabItem1 = async () => {
//   const { data } = await getFinancialList({
    
//   })
//   console.log('测试55', data)
// }
watch(hTilAct,(newVal, oldVal) => {
  console.log('测试', newVal)
})
// tabItem1();

const apiData0 = ref([]);
const bxbhList1 = ref([]);
const bxbhList2 = ref([]);
const bxbhList3 = ref([]);
const bxbhList4 = ref([]);
const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 1 }).then(res => res.data),
    getFinancialList({ coId: 677 }).then(res => res.data),
    getFinancialList({ coId: 671 }).then(res => res.data),
    getFinancialList({ coId: 701 }).then(res => res.data),
    getInfo({ coId: 1073, isTop: 1 }).then(res => res.data)
  ];
  const results = await Promise.all(promises);
  console.log('测试', results[4])
  apiData0.value = results[0].data;
  bxbhList1.value = results[1].data;
  bxbhList2.value = results[2].data;
  bxbhList3.value = results[3].data;
  bxbhList4.value = results[4].data;
};
getList();
</script>

<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.van-swipe {
  margin-bottom: 0.6rem;
  .van-swipe-item {
    .txt {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      transform: translateY(-46%);
      padding: 0 0.4rem;
    }
    h2 {
      font-size: 0.38rem;
      line-height: 0.44rem;
      font-weight: 600;
      color: #ffbb00;
      margin-bottom: 0.05rem;
    }
    h4 {
      position: relative;
      font-size: 0.24rem;
      color: #ffbb00;
      line-height: 0.4rem;
      font-weight: 600;
    }
    .line {
      margin: 0.17rem 0;
      width: 0.34rem;
      height: 0.02rem;
      background: #ffbb00;
    }
    p {
      font-size: 0.22rem;
      line-height: 0.36rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.til{
  position: relative;
  .more{
    position: absolute;
    right: 0;
    top: 0;
    font-size: .22rem;
    height: .46rem;
    line-height: .46rem;
    color: #999;
    padding-right: .18rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAPCAYAAADZCo4zAAABBElEQVQYlWWQIUsEURSFv/NmNNosJv+FqMVgE+yistVF1GBYsGgwLiiK4oDFIPoDDIJ/QKPFJIhJsAmCYXhz5A27sjMeeOHd+3HuvUdFUfQljQO9qqp+QgjYrp8kcmDO9iwQgC3AjCgVu8AbsAkc0lICnoEV2x+S9mzvtB3SvKeyLDuSvoE+sN4ABnoANgABF5KW20DSte1tqJe/BBbaADHG8xjjiaRJ4OgfkGXZNDA/+N4lqz/Zngoh3EqaAU6Bg1GHCUlXtlPzBthNE2tAUp5l2ZntReA+hNCVVA6vGJN0nOf5GvAoqQN8DW3TDvu2U8wvklJAn40kbS8B75JWgdfGScAvl3lVYiDwrr0AAAAASUVORK5CYII=) no-repeat right center;
    background-size: .08rem;
  }
}
.hTil {
  height: 0.63rem;
  border-bottom: 0.06rem solid #e1e1e1;
  em {
    float: left;
    font-size: 0.26rem;
    color: #2d322d;
    line-height: 0.46rem;
    height: 0.63rem;
    border-bottom: 0.06rem solid #f70000;
  }
}
.insurance {
  margin-bottom: 0.6rem;
  :deep(.ball){
    .van-tabs__wrap{
      .van-tab{
        flex: none;
        padding: 0 .2rem;
      }
    }
  }
  .hd {
    padding: 0.2rem 0 0.4rem;
    .van-tab{
      white-space: nowrap;
    }
    // .li {
    //   font-size: 0.24rem;
    //   color: #2d322d;
    //   height: 0.56rem;
    //   line-height: 0.56rem;
    //   padding: 0 0.2rem;
    //   border-radius: 0.56rem;
    //   margin-left: 0.3rem;
    //   transition: all 0.4s;
    //   &.on {
    //     color: #f70000;
    //     background: #fbeddc;
    //   }
    // }
  }
  .bd {
    .li {
      position: relative;
      padding: 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: flex-start;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      width: 0.9rem;
      height: 0.9rem;
      overflow: hidden;
      margin-top: .14rem;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      padding: 0.07rem 0 0;
      .row {
        margin-bottom: 0.14rem;
        * {
          display: inline-block;
          vertical-align: middle;
        }
      }
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      .label {
        height: 0.32rem;
        line-height: 0.32rem;
        padding: 0 0.13rem;
        border-radius: 0.32rem;
        color: #fff;
        background: #f70000;
        margin-left: 0.16rem;
      }
      :deep(.pBox){
        p {
          position: relative;
          padding-left: .2rem;
          font-size: 0.22rem;
          color: #646464;
          line-height: 0.36rem;
          padding-right: 1.6rem;
          &:before{
            position: absolute;
            left: 0;
            top: 0;
            font-size: .26rem;
            line-height: .36rem;
            content: "·";
            display: block;
            color: #666;
          }
        }
      }
    }
    .applyFor {
      position: absolute;
      right: 0.3rem;
      bottom: 0.27rem;
      font-size: 0.22rem;
      color: #9f9f9f;
      height: 0.54rem;
      line-height: 0.52rem;
      text-align: center;
      width: 1.2rem;
      border: 0.01rem solid #9f9f9f;
      border-radius: 0.25rem;
    }
  }
}
.creditWrap {
  padding-bottom: .3rem;
  .hTil {
    margin-bottom: 0.5rem;
  }
  .bd {
    .item {
      position: relative;
      height: 3.12rem;
      padding: 0.26rem 0.3rem;
      border: 0.01rem solid #ddd;
      border-radius: 0.28rem;
      margin-bottom: 0.3rem;
      background: url(~@/assets/images/financial/banBg.jpg) no-repeat;
      background-size: 100% 100%;
      &.last{
        height: auto;
        .name{
          height: .64rem;
        }
        p{
          position: relative;
          padding-left: .2rem;
          &:after{
            position: absolute;
            left: 0;
            top: 0;
            content: "-";
            display: block;
            font-size: .24rem;
            color: #646464;
            line-height: .36rem;

          }
        }
      }
    }
    .logo {
      height: 1rem;
      img {
        max-height: 100%;
      }
    }
    .name {
      height: 0.94rem;
      font-size: 0.3rem;
      line-height: 0.5rem;
      color: #181918;
      em {
        color: #959695;
        margin-left: 0.15rem;
      }
    }
    :deep(.ul) {
      display: flex;
      .li {
        font-size: 0.2rem;
        line-height: 0.32rem;
        color: #646464;
        &:nth-child(1) {
          width: 2.54rem;
        }
        &:nth-child(3) {
          margin-left: auto;
        }
        em {
          font-family: "Gilroy-Bold";
          font-size: 0.44rem;
          padding-right: 0.05rem;
          color: #f70000;
        }
      }
    }
    .labels {
      position: absolute;
      right: 0.3rem;
      top: 0.4rem;
      .it {
        width: 1.2rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        font-size: 0.2rem;
        color: #fff;
        background: #f78600;
        border-radius: 0.4rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}
.fadeInUp-enter-active, .fadeInUp-leave-active {  
  transition: all 1s;  
}  
.fadeInUp-enter, .fadeInUp-leave-to {  
  opacity: 0;
  transform: translateY(50px);
}

.hotWrap{
  margin-bottom: .6rem;
  .til{
    margin-bottom: .5rem;
  }
  .li{
    height: 2.6rem;
    border-radius: .2rem;
    padding: .35rem .24rem;
    background: url(~@/assets/images/financial/hotLiBg.jpg) no-repeat center;
    background-size: 100% 100%;
    .row{
      position: relative;
      display: flex;
      justify-content: space-between;
      h4{
        font-size: .26rem;
        color: #fff;
        line-height: .36rem;
        font-weight: 600;
      }
      .num{
        font-size: .22rem;
        color: #fff;
        line-height: .36rem;
      }
    }
    .p{
      font-size: .22rem;
      color: #fff;
      line-height: .36rem;
    }
    .label{
      margin-top: .6rem;
      display: flex;
      justify-content: space-between;
      .i{
        padding: 0 .15rem;
        height: .56rem;
        line-height: .56rem;
        font-size: .2rem;
        color: #f70202;
        border-radius: .04rem;
        background: linear-gradient(to bottom, #fff8f3, #ffe6d1);
        box-shadow: 0 .06rem .2rem rgba(51,51,51,.2);
      }
    }
  }
}
.solution{
  .til{
    margin-bottom: .5rem;
  }
  ul{
    display: flex;
    justify-content: space-between;
  }
  li{
    width: 3.24rem;
    height: 1.9rem;
    padding: .3rem;
    overflow: hidden;
    border-radius: .1rem;
    background: no-repeat center;
    background-size: cover;
    h4{
      font-size: .26rem;
      color: #fff;
      line-height: .36rem;
    }
    .btn{
      width: 1rem;
      height: .46rem;
      line-height: .44rem;
      font-size: .2rem;
      color: #fff;
      border: .01rem solid rgba(255,255,255,.3);
      border-radius: .46rem;
      margin-top: .4rem;
      text-align: center;
    }
  }
  .li1{
    background-image: url(~@/assets/images/financial/solutionItemBg1.jpg);
  }
  .li2{
    background-image: url(~@/assets/images/financial/solutionItemBg2.jpg);
  }
}
</style>

