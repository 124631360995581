<template>
  <div class="wrap">
    <nav-ber :title="'项目机械管理'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>项目机械管理</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>设备信息管理</h4>
                <div class="line"></div>
                <p>建立和维护工地设备的详细台账，包括设备编号、型号、规格、购买日期、保修期限等信息。用户可以轻松查看和更新设备信息，并进行分类、筛选和搜索，以便快速定位所需设备</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>设备检查</h4>
                <div class="line"></div>
                <p>创建设备维护计划，设置维护周期、任务和负责人，并进行实时跟踪和记录。通过合理的维护安排和预防性维护措施，可以延长设备的使用寿命，减少计划外停机时间。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>设备验收管理</h4>
                <div class="line"></div>
                <p>根据工地需求和相关标准，制定设备验收的具体标准和指标，记录和更新验收结果、评价设备的合格程度，并上传相关文件和照片作为验收凭证。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>设备维保</h4>
                <div class="line"></div>
                <p>对设备维保计划、维护记录和维修任务的全面管理；设备出现故障，可在APP端进行报修，自动通知维修责任人进行设备维修，并记录设备故障停机所用时长，作为划分责任的重要依据。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>塔吊物联监测</h4>
                <div class="line"></div>
                <p>实时监测塔吊的关键运行状态参数，包括载荷、倾斜度、回转速度、起升速度等。通过传感器的采集和监控系统的处理，用户可以获取塔吊的实时数据，并对其运行状态进行监测和分析</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/machineryPic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>升降机物联监测</h4>
                <div class="line"></div>
                <p>实时监测升降机的关键运行状态参数，包括速度、加速度、停靠位置等。通过传感器的采集和监控系统的处理，用户可以获取升降机的实时数据，并对其运行状态进行监测和分析。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

