<template>
  <div class="wrap">
    <van-button type="primary" @click="getUserLocation">获取地址</van-button>
    <h2>坐标: {{dizhi}}</h2>
    <main class="home">
      <div class="topBox">
        <div class="place" @click="openCitySelection">{{ city }}</div>
        <h2>华建通建筑<br>
        产业平台</h2>
        <p>建筑产业全场景、全链条、全要素的<br>
        数智化互联网服务平台</p>
      </div>
      <!-- 选择城市 -->
      <city-selection :showOverlay="isCitySelectionOpen" @getCity="setCity"></city-selection>
    </main>
    <h4>{{ res }}</h4>
  </div>
</template>
<script setup>
import CitySelection from '@/components/CitySelection.vue';
import { getNews } from '@/api/http';
import { NavBar } from 'vant';
import { ref } from 'vue';
const value = ref('');
const active = ref(0);
const city = ref('南宁');
const isCitySelectionOpen = ref(0);
const setCity = val => city.value = val;
const openCitySelection = () => {
  isCitySelectionOpen.value++;
}
let dizhi = ref([]);
let res = ref('')

const newsList = async () => {
  const res = await getNews({
    coId: 225,
    pageIndex: 0,
    pageSize: 4
  })
  console.log(res)
}
newsList();

// 获取地址
async function getUserLocation() {
  if (!navigator.geolocation) {
    res.value = "您的浏览器不支持地理定位。";
    // this.locationError = "您的浏览器不支持地理定位。";
    return;
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
    });

    dizhi.value = position;
  } catch (error) {
    res.value = `无法获取位置信息: ${error.message}`;
  }
}
</script>
<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.wrap{padding-top: 0;}
.home{
  padding: 0;
  background: url(~@/assets/images/home/homeTopBg.jpg) no-repeat top center;
  background-size: 100%;
}
.topBox{
  position: relative;
  padding: 1.75rem .4rem 0;
  height: 5.34rem;
  .place{
    position: absolute;
    left: .4rem;
    top: .46rem;
    height: .6rem;
    line-height: .6rem;
    font-size: .28rem;
    color: #fff;
    padding: 0 .28rem 0 .4rem;
    background: url(~@/assets/images/iconPlace.png) no-repeat left center / .23rem,
    url(~@/assets/images/iconDown.png) no-repeat right center / .14rem;
  }
  h2{
    font-size: .56rem;
    line-height: .66rem;
    color: #0d5fb2;
    font-weight: 600;
    margin-bottom: .2rem;
  }
  p{
    font-size: .24rem;
    color: #7c8ea6;
    line-height: .4rem;
  }
}
</style>

