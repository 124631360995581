<template>
  <div class="Waterfall">
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic1.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li lwjrd jr">
      <div class="icon">
        <img src="~@/assets/images/home/homeHotIcon1.png" alt="" />
      </div>
      <h3>劳务金融贷</h3>
      <h4>单笔借支额度:每个工人单笔贷款额度不超5000(每月一次)</h4>
      <p>
        借款要求：劳务公司质押产值单合同等相关资料,项目有使用一号工班劳务管理系统
      </p>
      <p>
        合作的金融机构：<br />
        鹏金所已服务客户数:5000+
      </p>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic2.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic3.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>东海建筑一体机</h4>
        <div class="price">
          <div class="num">价格面议</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li hjlw jr">
      <div class="icon">
        <img src="~@/assets/images/home/homeHotIcon2.png" alt="" />
      </div>
      <h3>华建劳务</h3>
      <h4>
        华建劳务SaaS是依托桂建通桂薪宝等监管端产品的数据能力，为企业打造增值服务与数据支撑。成为监管端产品的Plus版。
      </h4>
      <p>
        降低企业用工风险，提高企业用工质量，提供灵活用工人员的有效管理，提供更加灵活的发薪渠道...
      </p>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic4.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic5.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li aqsczr jr">
      <div class="icon">
        <img src="~@/assets/images/home/homeHotIcon3.png" alt="" />
      </div>
      <h3>安全生产责任保险</h3>
      <p>
        安全生产责任保险（简称安责险），是指保险机构对投保的生产经营单位发生的生产安全事故造成的人员伤亡和有关经济损失等予以赔偿并且为投保的生产经营单位提供事故预防服务的商业保险。
      </p>
    </div>
    <div class="li gszc jr">
      <div class="icon">
        <img src="~@/assets/images/home/homeHotIcon4.png" alt="" />
      </div>
      <h3>公司注册服务</h3>
      <h4>1元注册公司，2999元代理</h4>
      <p>有限责任公司注册，开启创业第一步。</p>
      <h4>股份公司注册</h4>
      <p>股份公司注册，一站式服务全程代办。</p>
      <h4>个体工商注册</h4>
      <p>个体工商户注册，全程高效办理</p>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { windowWidth } from 'vant/lib/utils';
import { ref, onMounted, nextTick } from 'vue';

let columnHeights = [ 0 , 0 ];
let appendElement = (element) => {
    // 计算新元素的高度
    const elementHeight = element.clientHeight;
    const style = window.getComputedStyle(element);
    const marginBottom = parseInt(style.marginBottom);

    const wdWid = window.outerWidth / 7.5;
    const between = wdWid * 0.26;
    const width = wdWid * 3.22;
    const liLeft = between + width;
    console.dir(element, elementHeight)
    
    // 寻找当前最短列的索引
    let minColumnIndex = 0;
    for (let i = 1; i < columnHeights.length; i++) {
        if (columnHeights[i] < columnHeights[minColumnIndex]) {
            minColumnIndex = i;
        }
    }

    // 设置元素的位置（这里假设使用绝对定位）
    element.style.position = 'absolute';
    element.style.top = `${columnHeights[minColumnIndex]}px`;
    element.style.left = `${minColumnIndex * liLeft}px`; // 假设每列宽度为250px

    // 更新最短列的高度
    columnHeights[minColumnIndex] += elementHeight + marginBottom;

    // // 将元素添加到DOM
    // document.querySelector('.Waterfall').appendChild(element);
    let maxHei = Math.max(...columnHeights);
    document.querySelector('.Waterfall').style.height = maxHei + 'px'
}
onMounted(() => {
    nextTick(() => {
        let wrap = document.querySelector(".Waterfall");
        let lis = wrap.getElementsByClassName("li");
        for(let i = 0; i < lis.length; i++){
            appendElement(lis[i]);
        }
    });
})
</script>
<style scoped lang="scss">
.Waterfall{
    position: relative;
}
.box {
}
.li {
  width: 3.22rem;
  border-radius: 0.1rem;
  background: #fff;
  margin-bottom: 0.26rem;
  overflow: hidden;
  .pic {
    height: 2.94rem;
    background: no-repeat center;
    background-size: cover;
  }
  .txt {
    padding: 0.18rem 0.2rem;
    h4 {
      font-size: 0.26rem;
      color: #333;
      line-height: 0.3rem;
      margin-bottom: 0.18rem;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 设置显示的行数 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-size: 0.29rem;
        color: #e62a2b;
        font-family: "Gilroy-Bold", "微软雅黑";
        em {
          font-size: 0.24rem;
        }
      }
      .label {
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.2rem;
        color: #fb8741;
        background: #fee7d9;
        padding: 0 0.08rem;
        border-radius: 0.04rem;
      }
    }
  }
  
  .icon{
        height: .5rem;
        img{
            max-height: .5rem;
        }
    }
}
.jr{
    padding: .3rem;
    background: linear-gradient(to bottom left, #f3ede8, #efdcce);
    &.hjlw{
        background: linear-gradient(to bottom left, #eaf2f9, #c9d7e2);
        h3{
            color: #374055;
        }
        h4{
            color: #384055;
        }
        p{
            color: #848ba1;
        }
    }
    &.aqsczr{
        background: linear-gradient(to bottom left, #edfbf7, #bad7d4);
        h3{
            color: #3f615d;
        }
        p{
            color: #3f615d;
        }
    }
    &.gszc{
        background: linear-gradient(to bottom left, #fdf1e1, #e6c59e);
        h3{
            color: #a56f47;
        }
        h4{
            color: #a56f47;
            margin-bottom: 0;
        }
        p{
            color: #a56f47;
        }
    }
    
    .icon{
        margin-bottom: .1rem;
    }
    h3{
        font-size: .26rem;
        color: #7a5131;
        line-height: .48rem;
        margin-bottom: .1rem;
        font-weight: bold;
        text-align: justify;
    }
    h4{
        font-size: .22rem;
        color: #7a5131;
        line-height: .3rem;
        margin-bottom: .1rem;
        font-weight: bold;
        text-align: justify;
    }
    p{
        font-size: .22rem;
        color: #999;
        line-height: .3rem;
        margin-bottom: .1rem;
    }
}
</style>






