import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import '@/assets/css/reset.css';
import '@/assets/css/public.scss';
import { Tabbar, TabbarItem, Search, Swipe, SwipeItem, Tab, Tabs, Area, Overlay, NavBar, Collapse, CollapseItem, Button, Popup, Picker, ImagePreview, Uploader, Dialog, Divider, IndexBar, IndexAnchor, Loading, List, DropdownMenu, DropdownItem } from 'vant';


// var _hmt = _hmt || [];
// (function() {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?47368e281399a63f0f97014a93aabc6a";
//   var s = document.getElementsByTagName("script")[0]; 
//   s.parentNode.insertBefore(hm, s);
// })();

const app = createApp(App)

// app.provide('_hmt', _hmt);

// 定义全局错误处理器
// app.config.errorHandler = (err, vm, info) => {
//     console.error(`Error: ${err.toString()}\nInfo: ${info}`);
//     // 这里可以添加更多的错误处理逻辑，比如上报错误到服务器
// };

app.use(store).use(router).mount('#app')
app.use(Tabbar)
.use(TabbarItem)
.use(Search)
.use(Swipe)
.use(SwipeItem)
.use(Tab)
.use(Tabs)
.use(Area)
.use(Overlay)
.use(NavBar)
.use(Collapse)
.use(CollapseItem)
.use(Button)
.use(Popup)
.use(Picker)
.use(ImagePreview)
.use(Uploader)
.use(Dialog)
.use(Divider)
.use(IndexBar)
.use(IndexAnchor)
.use(Loading)
.use(List)
.use(DropdownMenu)
.use(DropdownItem)


