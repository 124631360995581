<template>
  <div class="wrap">
    <nav-ber title="产品与服务渠道商"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <div class="content">
        <section class="lump2">
          <h3 class="cTil"><em>合作权益</em></h3>
          <div class="ul">
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/distributorIcon1.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>收益奖励</h4>
                <p>
                  根据全年的销售业绩和合作情况（完成情况）给予一定的收益奖励（常规佣金、一次性奖励、项目服务奖励、市场拓展奖励）。
                </p>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/distributorIcon2.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>培训与支持</h4>
                <p>
                  提供产品知识、销售技巧等方面的培训和支持，帮助渠道商提高业务能力和专业水平。
                </p>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/distributorIcon3.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>市场支持</h4>
                <p>
                  提供全面的市场支持，包括市场营销策略、促销活动、广告宣传等。
                </p>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/distributorIcon4.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>风险可控</h4>
                <p>
                  平台已经建立起一定的知名度和美誉度，同时严选服务商为信誉保证，只需专注于市场拓展和销售。
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="lump3">
          <h3 class="cTil"><em>流程</em></h3>
          <div class="ul wow fadeInUp50 animated">
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/2024052516091470371e99-4afb-41ac-96e4-e8d07420d7ed.png"
                    alt=""
                  />
                </div>
                <p>明确意向</p>
                <em>01</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/2024052516091434d78c26-24be-48e3-a14a-318d35f391df.png"
                    alt=""
                  />
                </div>
                <p>提交材料</p>
                <em>02</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/20240525160914a32292db-6985-429c-a463-12d70a3c2a5e.png"
                    alt=""
                  />
                </div>
                <p>材料初审</p>
                <em>03</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/202405251609140ec6055f-c65c-49d0-b48b-6f71942fb21e.png"
                    alt=""
                  />
                </div>
                <p>双方交流</p>
                <em>04</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/2024052516091472eaa179-fe4c-4fe9-8aeb-9ea152ef9f66.png"
                    alt=""
                  />
                </div>
                <p>初步评定</p>
                <em>05</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/20240525160914c01aa4d8-dff2-4bbd-bd81-fff42dde800f.png"
                    alt=""
                  />
                </div>
                <p>实地考察</p>
                <em>06</em>
              </div>
            </div>
            <div class="line">
              <img src="/images/recruit/ico20.png" alt="" />
            </div>
            <div class="li">
              <div class="txt">
                <div class="i">
                  <img
                    src="      /hjt-door/202405/gw/hjt/202405251609145cdce83f-8de8-4bc1-b837-77b27a0f478b.png"
                    alt=""
                  />
                </div>
                <p>合作签署</p>
                <em>07</em>
              </div>
            </div>
          </div>
        </section>
        <van-button
          class="cApplyBtn"
          @click="
            $router.push({
              path: '/consultform',
              query: { plateArr: '产品与服务渠道商' },
            })
          "
          >申请办理</van-button
        >
      </div>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);

const modules = [Pagination];
const pagination = {
  clickable: true,
};

const rightsModules = [Pagination];
const rightsPagination = {
  clickable: true,
};

const apiData0 = [ 
  {
    image: '/hjt-door/202407/gw/hjt/202407171501049da278aa-466e-4424-967c-f043c8752c22.png?x-oss-process=image/resize,p_50',
    newsId: 0,
    newsContent: '<h3>产品与服务渠道商</h3><p>产品与服务渠道商是平台市场推广者，通过共享资源、协作发展、互利共赢的方式，协同平台将产品或服务传递到最终用户。实现供应链各环节的协同和高效运作。</p>'
  }
]
</script>
<style scoped lang="scss">
.content {
  section {
    margin-bottom: 0.6rem;
  }
  .cTil {
    margin-bottom: 0.33rem;
  }
  .pic {
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: 0.03rem solid #c7c7c7;
    margin-top: 0.3rem;
  }
  .cTil {
    text-align: center;
    em:after {
      right: 0;
      margin: 0 auto;
    }
  }
  .pBox {
    h4 {
      font-size: 0.22rem;
      color: #333;
      line-height: 0.36rem;
    }
    p {
      text-indent: 2em;
      margin-bottom: 0.36rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.lump2 {
  .ul {
    .li {
      position: relative;
      padding: 0.27rem 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: center;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      position: absolute;
      left: 0.3rem;
      top: 0.5rem;
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      min-height: 1.2rem;
      margin-left: 1.2rem;
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      p {
        text-indent: 0;
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
    .btn {
      float: left;
      padding: 0 0.2rem;
      height: 0.48rem;
      font-size: 0;
      line-height: 0.48rem;
      border-radius: 0.48rem;
      text-align: center;
      background: #f70000;
      margin-top: 0.2rem;
      em {
        font-size: 0.22rem;
        color: #fff;
        padding-right: 0.2rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.08rem;
      }
    }
  }
}

.content .lump3{margin-bottom: .2rem;}
.lump3{
  .ul{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin:.7rem -.4rem 0;
  }
  .li {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: var(--box-shadow);
    display: flex;
    height: 1.4rem;
    justify-content: center;
    width: 1.4rem;
    padding-top: .2rem;
    margin: 0 .1rem .4rem;
    position: relative;
    .i{
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: .4rem;
      max-width: .4rem;
      margin: 0 auto .1rem;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    p {
      color: gray;
    }
    em {
      align-items: center;
      background: #d7000f;
      border-radius: 50%;
      color: gray;
      color: #fff;
      display: flex;
      font-family: "Gilroy-Bold";
      font-size: .24rem;
      height: .5rem;
      justify-content: center;
      margin: auto;
      position: absolute;
      left: 50%;
      top: -.25rem;
      width: .5rem;
      margin-left: -.25rem;
    }
  }
}
</style>

