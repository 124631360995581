import { createStore } from 'vuex'

export default createStore({
  state: {
    safetyTop: null, //设备头部安全距离
    iosAudit: false, //ios审核隐藏金融支付
    localHttp: '' //本地测试前缀，上线为空 https://m.huajiantong.com/
  },
  getters: {
  },
  mutations: {
    setSafetyArea(state, val) {
      state.safetyTop = val;
    },
    setIosAuditState(state, val){
      state.iosAudit = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
