<template>
  <div class="wrap">
    <nav-ber :title="'应用案例'"></nav-ber>
    <main>
      <div class="viw">
        <div class="pic"><img src="~@/assets/images/engineering/engineeringPic14.jpg" alt=""></div>
        <div class="txt">南宁绿地中央广场</div>
      </div>
      <div class="textTab">
        <van-tabs class="border" v-model:active="active" color="#f70000" line-width="56" title-inactive-color="#2d322d">
          <van-tab title="项目概况"></van-tab>
          <van-tab title="客户价值"></van-tab>
          <van-tab title="产品服务"></van-tab>
          <van-tab title="取得效果"></van-tab>
        </van-tabs>
        <div class="bd">
          <div class="item" v-show="active === 0">
            <p>南宁绿地中央广场房地产项目D号地块（二期）（沿街商业、D2号楼、D3号楼、地下室2区、地下室3区）位于南宁市青秀区东葛路126号。D2号楼地下4层、地上29层,地下室裙房采用框架结构，地上部分采用框架-核心筒结构，D2号楼高度145.98米；D3号楼地下5层、地上19层,地下室裙房采用框架结构，地上部分采用框架剪力墙结构，D3号楼高度96.86米；总建筑面积93906.69㎡，其中，沿街商业地上740.39㎡、D2号楼地上37728.32㎡、D3号楼地上18091.19㎡、地下室2区30888.75㎡、地下室3区6457.99㎡。</p>
          </div>
          <div class="item" v-show="active === 1">
            <p>南宁绿地中央广场工地接入了6台摄像头、1台AI设备、2台起重机、1台升降机监控设备，累计实名工人数568人；通过智慧工地软硬件一体化建设，轻松掌握工程现场整体趋势，为保障现场项目质量、安全施工创造了有利条件（安全事故0发生、节约安全管理成本15%、安全问题100%销项闭环）。</p>
          </div>
          <div class="item" v-show="active === 2">
            <p>1.视频监控：对工地现场、工地办公区、生活区布设视频监控，提供现场重点区域实时画面<br>
            2.起重机、升降机监控：监测工地现场大型施工设备、重点施工物料、特殊施工工艺、危险施工场景的作业信息<br>
            3.隐患识别整改：对人的不安全行为进行AI隐患识别，或巡检过程中手动发起隐患整改</p>
          </div>
          <div class="item" v-show="active === 3">
            <p>1.实现监管部门对各个工地现场的施工进度、施工安全防范、建设质量、环境保护进行统一管理，确保项目管理者实时了解现场情况、监管现场设备财产安全，防范外来非法入侵<br>
            2.保障施工的安全和质量，为监管部门和项目管理者对现场施工异常情况的及时跟进、处理和救援，防微杜渐，减少安全事故发生<br>
            3.对易发高发事故的风险隐患实时监控，根据监控报警标准形成对应隐患，并推动人员及时进行整改闭环，防范遏制事故发生</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { ref } from 'vue';
const active = ref(0);
</script>
<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
main{
  padding: 0;
}
.viw{
  padding: .8rem .4rem 0;
  height: 6.7rem;
  background: url(~@/assets/images/engineering/adhibitionBg.jpg) no-repeat top center;
  background-size: contain;
  .pic{
    border-radius: .28rem .28rem 0 0;
    overflow: hidden;
  }
  .txt{
    height: 1.26rem;
    line-height: 1.26rem;
    text-align: center;
    font-size: .38rem;
    color: #fff;
    border-radius: 0 0 .28rem .28rem;
    background: url(~@/assets/images/engineering/adhibitionItemBg.jpg) no-repeat top center;
    background-size: 100% 100%;
  }
}
.textTab{
  padding: .4rem;
}
</style>
