<template>
  <div class="wrap">
    <nav-ber :title="'智能硬件解决方案'"></nav-ber>
    <main>
      <div class="topBox">
        <h2>智能硬件 建立无限连接</h2>
        <h5>
          Smart Hardware<br />
          Makes Unlimited Connections
        </h5>
      </div>
      <div class="content">
        <div class="projectList">
          <van-tabs
            v-model:active="active"
            class="ball"
            @click-tab="scrollToSection"
          >
            <van-tab title="全部"></van-tab>
            <van-tab title="监控摄像头"></van-tab>
            <van-tab title="塔机监测"></van-tab>
            <van-tab title="升降机监测"></van-tab>
            <van-tab title="扬尘监控"></van-tab>
            <van-tab title="AI盒子"></van-tab>
            <van-tab title="车辆未清洗"></van-tab>
            <van-tab title="高支模监测"></van-tab>
            <van-tab title="卸料平台"></van-tab>
            <van-tab title="基坑监测"></van-tab>
          </van-tabs>
          <van-collapse class="bd" v-model="activeNames">
            <van-collapse-item
              data-id="监控摄像头"
              name="1"
              class="li"
              :class="['li', activeNames.includes('1') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic1.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>监控摄像头</h4>
                    <p>监控摄像头</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:实时监控现场</em></h3>
                <div class="cnt">
                  <p>
                    运用主流视频监控设备，对施工现场、生活区、主要出入口等重要部位进行实时远程视频监控，支持摄像头设备的添加、查询，以及单个摄像头的全屏、回放、录像、对话等功能
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicJKSXT.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="塔机监测"
              name="2"
              class="li"
              :class="['li', activeNames.includes('2') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic2.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>塔机检测</h4>
                    <p>塔机运行状态监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:塔吊机械监控</em></h3>
                <div class="cnt">
                  <p>
                    将项目塔吊设备关联绑定对应监控设备，随时查看设备实时运行数据，如设备独立高度、当前载重、回转角度、风速等，超过设定限值则立即报警。实时监控塔吊运行情况，防范于未然。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicTJJC.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="升降机监测"
              name="3"
              class="li"
              :class="['li', activeNames.includes('3') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic3.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>升降机监测</h4>
                    <p>升降机运行状态监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:升降机械监控</em></h3>
                <div class="cnt">
                  <p>
                    将项目升降机械设备关联绑定对应监控设备，随时查看设备实时运行数据，如设备独立高度、当前载重、当前人数、风速等，超过设定限值则立即报警。实时监控升降机运行情况，防范于未然。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicSJJJC.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="扬尘监控"
              name="4"
              class="li"
              :class="['li', activeNames.includes('4') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic4.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>扬尘监控</h4>
                    <p>现场环境实时监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:扬尘噪音监控</em></h3>
                <div class="cnt">
                  <p>
                    将施工现场扬尘噪音设备接入平台，当监测数值超过设定阈值时，将自动报警；管理人员可查看扬尘和噪音的24小时、最近7日、15日变化趋势，掌握现场环境情况并及时进行调整，落实绿色文明施工要求
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicYCJK.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="AI盒子"
              name="5"
              class="li"
              :class="['li', activeNames.includes('5') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic5.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>AI盒子</h4>
                    <p>不安全行为智能识别</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:不安全行为识别监测</em></h3>
                <div class="txtSwiper">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="txt">
                          <p>
                            安全帽检测：对进入作业区域的人员进行自动识别：如检测到人员未佩戴安全帽，可立即报警，确保人员安全
                          </p>
                        </div>
                        <div class="pic">
                          <img
                            src="~@/assets/images/engineering/intelligentPicAI1.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="txt">
                          <p>
                            反光衣检测：对出入口、通道等区域的人员进行自动识别，如未穿反光衣，则立即推送报警，确保人员安全
                          </p>
                        </div>
                        <div class="pic">
                          <img
                            src="~@/assets/images/engineering/intelligentPicAI2.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="txt">
                          <p>
                            烟火检测：对监控的作业区域内进行烟火识别，如检测到明烟或明火，则立即推送报警；在发生后就通知进行人员管控，防止危险源扩散
                          </p>
                        </div>
                        <div class="pic">
                          <img
                            src="~@/assets/images/engineering/intelligentPicAI3.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="txt">
                          <p>
                            危险区域检测：预先设置好的危险区域，当人员闯入时立即报警，自动生成隐患通知到对应人员进行管控，确保员工的人身安全
                          </p>
                        </div>
                        <div class="pic">
                          <img
                            src="~@/assets/images/engineering/intelligentPicAI4.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <!-- 更多 slides -->
                    </div>
                    <!-- Swiper Pagination -->
                    <div class="user-pagination">
                      <div :class="['dot', { on: swiperAct === 0 }]"></div>
                      <div :class="['dot', { on: swiperAct === 1 }]"></div>
                      <div :class="['dot', { on: swiperAct === 2 }]"></div>
                      <div :class="['dot', { on: swiperAct === 3 }]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="车辆未清洗"
              name="6"
              class="li"
              :class="['li', activeNames.includes('6') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic6.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>车辆未清洗</h4>
                    <p>车辆清洗抓拍监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:车辆未冲洗拍摄</em></h3>
                <div class="cnt">
                  <p>
                    识别车牌号和车牌颜色；识别车辆通过冲洗台正常冲洗；识别车辆未冲洗绕道离开；识别车辆通过冲洗台未冲洗；识别车辆通过冲洗台冲洗时长不足；记录车辆驶离时的照片、视频、时间戳；将违规车辆相关数据上报智慧工地云平台；设备异常掉线上报告警。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicCLWQX.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="高支模监测"
              name="7"
              class="li"
              :class="['li', activeNames.includes('7') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic7.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>高支模监测</h4>
                    <p>高支模实时监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:高支模监测</em></h3>
                <div class="cnt">
                  <p>
                    内容包含顶杆传感器、扣件监测器，支撑体监测器，承压传感器，全面检测模板沉降、整体位移、顶杆失稳、扣件失效，支撑体系倾斜，承压过大。通过平台提供的公共开放接口与监测设备关联，实时监测高支模下沉、位移、松动等测量数据，将对超过阈值的参数及时回到平台，并推送给相关的管理人员做到及时响应。<br />
                    实时了解高支模状态，数据异常情况提前预警，使现场安全管理人员掌握第一手数据与报警，避免坍塌、人为破坏等问题的发生。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicGZMJC.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="卸料平台"
              name="8"
              class="li"
              :class="['li', activeNames.includes('8') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic8.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>卸料平台</h4>
                    <p>卸料平台载重监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:卸料平台检测</em></h3>
                <div class="cnt">
                  <p>
                    系统实时监测卸料平台载重数据并上传云平台。当前状态实时显示，同时具备声光预警报警联动等功能，提醒操作员及时采取正确的处理措施，有效地防范和减少卸料平台安全生产事故。<br />
                    实时了解卸料平台状态，数据异常情况提前预警，使现场安全管理人员掌握第一手数据与报警，避免超负荷、人为破坏等问题的发生。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicXLPT.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item
              data-id="基坑监测"
              name="9"
              class="li"
              :class="['li', activeNames.includes('9') ? 'on' : '']"
            >
              <template #title>
                <div class="viw">
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentProductPic9.png"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h4>基坑监测</h4>
                    <p>基坑安全监测</p>
                  </div>
                </div>
              </template>
              <div class="hid">
                <h3 class="cTil"><em>应用场景:基坑监测</em></h3>
                <div class="cnt">
                  <p>
                    提供的公共开放接口与基坑监测设备关联，全天候监测地下水位、沉降、支撑轴力、立柱内力、深层土体位移、土压力等，实时传输至云端分析，图表反映测量点数据变化，统计基坑告警信息，并分析历史报警原因，及时预警危险态势，辅助基坑安全管理，预防生产安全事故的发生。<br />
                    实时了解基坑状态，数据异常情况提前预警，使现场安全管理人员掌握第一手数据与报警，避免坍塌、人为破坏等问题的发生。
                  </p>
                  <div class="pic">
                    <img
                      src="~@/assets/images/engineering/intelligentPicJKJC.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="bom">
          <div class="case">
            <h2 class="til"><em>应用案例</em></h2>
            <div class="item">
              <h3>南宁绿地中央广场</h3>
              <div class="li">
                <div class="pic"><img src="~@/assets/images/engineering/engineeringPic14.jpg" alt=""></div>
                <div class="txt">
                  <h4>应用价值</h4>
                  <p>通过智慧工地软硬件―体化建设，轻松掌握工程现场整体趋势，为保障现场项目质量、安全施工创造了有利条件（安全事故0发生、节约安全管理成本15%、安全问题100%销项闭环）</p>
                  <router-link to="/engineering/adhibition">查看详情</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="logoList">
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZTJS.jpg" alt=""></div><div class="p">中天建设集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团第三建筑工程有限责任公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团控股有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团建筑工程总承包有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建三局第一建设工程有限责任公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建三局第二建设工程有限责任公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第八工程局有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建科工集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建科工集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoTYJZ.jpg" alt=""></div><div class="p">腾越建筑科技集团</div></div>
            <van-button class="more" v-if="!logoArr" plain hairline type="primary" block color="#9f9f9f" style="height: .9rem; border-color: #e6e6e6; margin-top: .3rem;" @click="logoArr = true">查看更多</van-button>
            <div class="hide" v-else>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoJBJS.jpg" alt=""></div><div class="p">佳邦建设集团有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoJBJS.jpg" alt=""></div><div class="p">佳邦建设集团有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团第四建筑工程有限责任公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGZT.jpg" alt=""></div><div class="p">中国中铁股份有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工第一建筑工程集团有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">五建集团</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第五工程局</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第七工程局有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第三工程局有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXYH.jpg" alt=""></div><div class="p">广西裕华建设集团</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoBTJT.jpg" alt=""></div><div class="p">广西路桥工程集团有限公司</div></div>
              <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogGXHY.jpg" alt=""></div><div class="p">广西华业建筑工程有限公司</div></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import Swiper, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import NavBer from "@/components/NavBer.vue";
import { ref, onMounted, onUpdated } from "vue";
const activeNames = ref(["0"]);
const active = ref(0);
const containerRef = ref(null);
const subsidyHei = ref(0);
const swiperAct = ref(0);
const isCollapseOpen = ref(false);
const swiperInstance = ref(null);
const logoArr = ref(false);

function scrollToSection(agms) {
  const targetId = agms.title;
  const targetElement = document.querySelector(`[data-id="${targetId}"]`);
  console.log(agms)
  if (!targetElement) return;
  let idx = '' + agms.name;
  if(!activeNames.value.includes(idx)){
    activeNames.value.push(idx)
  }
  const scrollTop = targetElement.offsetTop + subsidyHei.value; // 考虑到可能有固定头部，减去头部高度
  console.log(typeof agms.name)
  window.scrollTo({ top: scrollTop, behavior: "smooth" });
}

function initSwiper() {
  if (isCollapseOpen.value && !swiperInstance.value) {
    // 在这里创建并初始化 Swiper 实例
    swiperInstance.value = new Swiper(".swiper-container", {
      // Swiper 配置项...
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      on: {
        slideChangeTransitionStart: function () {
          swiperAct.value = this.activeIndex;
        },
      },
    });
  }
}


onMounted(() => {
  // 获取页面滚动容器（通常是<body>或指定的某个<div>）
  containerRef.value =
    document.querySelector("#scrollContainer") || document.body;
  const topBoxHei = document.querySelector(".topBox").offsetHeight;
  subsidyHei.value = topBoxHei;
});

onUpdated(() => {
  isCollapseOpen.value = activeNames.value.includes('5');
  initSwiper();
});
</script>

<style scoped lang="scss">
@import "~@/assets/css/vanTabs.scss";
.wrap {
  padding-bottom: 0;
}
main {
  padding: 0;
  background: url(~@/assets/images/engineering/intelligentTopBg.jpg) no-repeat top center;
  background-size: 100%;
}
.topBox {
  height: 3.55rem;
  padding: 1.2rem 0.4rem 0;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.projectList {
  padding: 0.6rem 0.4rem;
  background: #fff;
  .van-tabs {
    margin-bottom: 0.6rem;
  }
  .li {
    padding: 0.3rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    border: 0.03rem solid #fff;
    border-radius: var(--border-radius);
    margin-bottom: 0.3rem;
    transition: background 0.4s;
    &.on {
      border-color: #f60707;
      background: url(~@/assets/images/financial/financialEnsureRiskControlItemBg.png)
        no-repeat bottom center;
    }
    &:after {
      display: none;
    }
    :deep(.van-cell) {
      padding: 0 0.3rem;
      margin: 0 -0.3rem;
      width: auto;
      background: transparent;
      &:after {
        display: none;
      }
      .van-icon {
        display: none;
      }
    }
    :deep(.van-collapse-item__content) {
      padding: 0;
      background: transparent;
    }
    .viw {
      display: flex;
      height: 2rem;
      align-items: center;
      .pic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 100%;
        margin-right: 0.9rem;
        img {
          max-width: 90%;
          max-height: 90%;
        }
      }
      .txt {
        h4 {
          font-size: 0.28rem;
          line-height: 0.44rem;
          color: #000;
        }
        p {
          font-size: 0.22rem;
          line-height: 0.36rem;
          color: rgba(45, 50, 45, 0.6);
        }
      }
    }
    .hid {
      padding-top: 0.3rem;
      .cTil {
        margin-bottom: 0.3rem;
      }
      .cnt {
        p {
          font-size: 0.22rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}

.bom {
  background: #f1f1f1;
}
.case {
  padding: 0.6rem 0.4rem 1rem;
  .til {
    display: flex;
    justify-content: center;
    height: 0.7rem;
    border-bottom: 0.06rem solid #e1e1e1;
    font-size: 0.36rem;
    color: #2d322d;
    line-height: 0.56rem;
    text-align: center;
    em {
      height: 0.7rem;
      border-bottom: 0.06rem solid #f70000;
    }
  }
  .item {
    padding: 0.2rem 0 0;
    h3 {
      font-size: 0.28rem;
      color: #2d322d;
      line-height: 0.48rem;
      text-align: center;
      margin-bottom: 0.2rem;
    }
    .li {
      overflow: hidden;
      border-radius: 0.28rem;
    }
    .txt {
      position: relative;
      height: 3.9rem;
      padding: 0.5rem 0.4rem;
      background: url(~@/assets/images/engineering/engineeringCaseItemBg.jpg)
        no-repeat center;
      background-size: 100% 100%;
      h4 {
        font-size: 0.28rem;
        color: #fff;
        line-height: 0.4rem;
        margin-bottom: 0.3rem;
      }
      p {
        font-size: 0.22rem;
        color: rgba(255, 255, 255, 0.9);
        line-height: 0.36rem;
      }
      a {
        position: absolute;
        right: 0.4rem;
        bottom: 0.4rem;
        width: 1.5rem;
        height: 0.54rem;
        text-align: center;
        color: #fff;
        line-height: 0.52rem;
        border-radius: 0.54rem;
        border: 0.01rem solid rgba(255, 255, 255, 0.4);
      }
    }
  }
}
.logoList{
  display: flex;
  flex-wrap: wrap;
  padding: 0 .4rem .6rem;
  justify-content: space-between;
  .li{
    width: 100%;
    height: 1.2rem;
    padding: 0 .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: var(--border-radius);
    margin-bottom: .14rem;
    .logo{
      height: .8rem;
      width: 2.34rem;
      img{
        max-height: 100%;
      }
    }
    .p{
      flex: 1;
      font-size: .22rem;
      color: #5d5d5d;
      line-height: .36rem;
    }
  }
  .more{
    background: transparent;
  }
}
.swiper-container{
  .txt {
    margin-bottom: 0.3rem;
  }
}
.user-pagination{
  margin-top: .3rem;
  display: flex;
  justify-content: center;
  .dot{
    width: .1rem;
    height: .1rem;
    background: #9b9b9b;
    border-radius: 50%;
    margin: 0 .05rem;
    &.on{
      background: #f70000;
    }
  }
}
.van-collapse{
  position: static;
}
</style>

