<template>
  <div class="wrap">
    <nav-ber :title="'保险保函产品页面'"></nav-ber>
    <main>
      <div class="hd">
        <div :class="['li', {'on': active == 0}]" @click="cutHTil(0)">工程保险</div>
        <div :class="['li', {'on': active == 1}]" @click="cutHTil(1)">工程保函</div>
        <div :class="['li', {'on': active == 2}]" @click="cutHTil(2)">机械设备险</div>
        <div :class="['li', {'on': active == 3}]" @click="cutHTil(3)">房屋综合险</div>
        <div :class="['li', {'on': active == 4}]" @click="cutHTil(4)">意外伤害险</div>
        <!-- <div :class="['li', {'on': active == 5}]" @click="cutHTil(5)">查看全部</div> -->
      </div>
      <div class="bd">
        <div class="ul" v-show="active === 0">
          <div class="li" v-for="item in bxbhList1" :key="item.newsId">
            <div class="icon">
              <img :src="item.appImg" alt=""/>
            </div>
            <div class="txt">
              <div class="row">
                <h4>{{item.newsTitle}}</h4>
              </div>
              <div class="pBox" v-html="item.newsTags"></div>
            </div>
            <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
          </div>
        </div>
        <div class="ul" v-show="active === 1">
          <div class="li" v-for="item in bxbhList2" :key="item.newsId">
            <div class="icon">
              <img :src="item.appImg" alt=""/>
            </div>
            <div class="txt">
              <div class="row">
                <h4>{{item.newsTitle}}</h4>
              </div>
              <div class="pBox" v-html="item.newsTags"></div>
            </div>
            <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
          </div>
        </div>
        <div class="ul" v-show="active === 2">
          <div class="li" v-for="item in bxbhList3" :key="item.newsId">
            <div class="icon">
              <img :src="item.appImg" alt=""/>
            </div>
            <div class="txt">
              <div class="row">
                <h4>{{item.newsTitle}}</h4>
              </div>
              <div class="pBox" v-html="item.newsTags"></div>
            </div>
            <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
          </div>
        </div>
        <div class="ul" v-show="active === 3">
          <div class="li" v-for="item in bxbhList4" :key="item.newsId">
            <div class="icon">
              <img :src="item.appImg" alt=""/>
            </div>
            <div class="txt">
              <div class="row">
                <h4>{{item.newsTitle}}</h4>
              </div>
              <div class="pBox" v-html="item.newsTags"></div>
            </div>
            <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
          </div>
        </div>
        <div class="ul" v-show="active === 4">
          <div class="li" v-for="item in bxbhList5" :key="item.newsId">
            <div class="icon">
              <img :src="item.appImg" alt=""/>
            </div>
            <div class="txt">
              <div class="row">
                <h4>{{item.newsTitle}}</h4>
              </div>
              <div class="pBox" v-html="item.newsTags"></div>
            </div>
            <div class="applyFor" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': item.newsId, 'type': item.newsTitle}})">去申请</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo, getFinancialList } from '@/api/http';
import { ref } from 'vue';
const active = ref(0);
const coIdList = [677, 671, 685, 667, 701];
const bxbhList1 = ref([]);
const bxbhList2 = ref([]);
const bxbhList3 = ref([]);
const bxbhList4 = ref([]);
const bxbhList5 = ref([]);
const cutHTil = async (num) => {
  active.value = num;
  if(active.value === 0 && !bxbhList1.value.length){
    let { data: res } = await getFinancialList({ coId: coIdList[active.value], pageSize: 30 });
    bxbhList1.value = res.data;
  }else if(active.value === 1 && !bxbhList2.value.length){
    let { data: res } = await getFinancialList({ coId: coIdList[active.value], pageSize: 30 });
    bxbhList2.value = res.data;
  }else if(active.value === 2 && !bxbhList3.value.length){
    let { data: res } = await getFinancialList({ coId: coIdList[active.value], pageSize: 30 });
    bxbhList3.value = res.data;
  }else if(active.value === 3 && !bxbhList4.value.length){
    let { data: res } = await getFinancialList({ coId: coIdList[active.value], pageSize: 30 });
    bxbhList4.value = res.data;
  }else if(active.value === 4 && !bxbhList5.value.length){
    let { data: res } = await getFinancialList({ coId: coIdList[active.value], pageSize: 30 });
    bxbhList5.value = res.data;
  }
}
cutHTil(0);
</script>

<style scoped lang="scss">
main {
  padding: 0.6rem 0.4rem;
  background: url(~@/assets/images/cBg.jpg) no-repeat top;
  background-size: 100%;
}
.hd{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: .44rem;
  margin-left: -.2rem;
  width: 7.1rem;
  .li{
    width: 2.14rem;
    height: .66rem;
    line-height: .64rem;
    border-radius: .66rem;
    border: .01rem solid #dcdcdc;
    border-radius: .3rem;
    font-size: .22rem;
    color: #333;
    text-align: center;
    margin: 0 .07rem .16rem;
    transition: all .4s;
    &.on{
      color:#f70000;
      background: #fbeddb;
      border-color: #fbeddb;
    }
  }
}

.bd {
  .li {
    position: relative;
    padding: 0.3rem;
    display: flex;
    min-height: 2.1rem;
    align-items: flex-start;
    margin-bottom: 0.3rem;
    border-radius: 0.28rem;
    background: #fff;
    box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
  }
  .icon {
    width: 0.9rem;
    height: 0.9rem;
    overflow: hidden;
    margin-top: .14rem;
    margin-right: 0.3rem;
  }
  .txt {
    flex: 1;
    padding: 0.07rem 0 0;
    .row {
      margin-bottom: 0.14rem;
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
    h4 {
      font-size: 0.28rem;
      color: #2d322d;
      line-height: 0.42rem;
    }
    .label {
      height: 0.32rem;
      line-height: 0.32rem;
      padding: 0 0.13rem;
      border-radius: 0.32rem;
      color: #fff;
      background: #f70000;
      margin-left: 0.16rem;
    }
    :deep(.pBox){
      p {
        position: relative;
        padding-left: .2rem;
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
        padding-right: 1.6rem;
        &:before{
          position: absolute;
          left: 0;
          top: 0;
          font-size: .26rem;
          line-height: .36rem;
          content: "·";
          display: block;
          color: #666;
        }
      }
    }
  }
  .applyFor {
    position: absolute;
    right: 0.3rem;
    bottom: 0.27rem;
    font-size: 0.22rem;
    color: #9f9f9f;
    height: 0.54rem;
    line-height: 0.52rem;
    text-align: center;
    width: 1.2rem;
    border: 0.01rem solid #9f9f9f;
    border-radius: 0.25rem;
  }
}
</style>

