<template>
  <div class="wrap">
    <nav-ber :title="' 项目安全管理'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>项目安全管理</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>隐患排查</h4>
                <div class="line"></div>
                <p>对现场隐患进行排查，做到过程跟踪记录；利用安全管理平面图，清晰获知检查区域、隐患点，所查出的安全隐患预警进行逐级推送，控制隐患在项目层级中及时解决，根据检查内容形成安全隐患整改统计、安全隐患预警统计、隐患类型统计、隐患等级统计、分包安全管理统计等大数</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>安全隐患监测</h4>
                <div class="line"></div>
                <p>实时采集视频图像，利用AI算法自动识别安全隐患及人员违规行为，并将隐患信息推送给管理人员，实现对建筑工地的安全监管、险情应急调度，保障现场和人员安全。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>安全巡检</h4>
                <div class="line"></div>
                <p>为保障现场项目质量与安全施工，管理人员更了解工地现场施工情况和及时发现问题，企业通过创建巡检任务，制定要检查的项目、检查时间、检查周期、检查项、执行人员等具体事项，项目管理人员在规定时间内到工地现场进行巡检，如发现异常可上传隐患照片并描述现场情况，采用线上与线下结合模式实现巡检任务闭环</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>安全资料管理</h4>
                <div class="line"></div>
                <p>上传各类工地安全资料，如安全计划、安全手册、操作规程、安全验收等。可以根据不同的项目和文件类型进行分类和整理，方便后续的检索和查阅。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>安全日志</h4>
                <div class="line"></div>
                <p>能够提供关键的安全信息并识别潜在的安全风险，并根据用户需求生成定制化的日志报表，支持快速检索安全日志；准确记录和分析安全日志有助于改善工地的安全管理水平，以及及时采取措施保障工人和设备的安全健康。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/safetyPic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>安全分析</h4>
                <div class="line"></div>
                <p>通过图表和图形化界面对安全隐患进行监测和评估，分析安全隐患来源、隐患高发时段、隐患整改闭环情况、整改时间等，更有效地管理安全问题，确保工地的安全达到最佳水平</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

