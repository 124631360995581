<template>
  <div class="wrap">
    <nav-ber :title="'立即咨询'"></nav-ber>
    <main>
      <div class="form">
        <div :class="['row', {'anomaly': verify && formData.name === ''}]">
          <label for="name" class="must">申请人</label>
          <input type="text" id="name" placeholder="请输入申请人名称" v-model="formData.name" :maxlength="20"/>
          <div class="warn">申请人不能为空</div>
        </div>
        <div :class="['row', {'anomaly': verify && !phoneVerify}]">
          <label for="phone" class="must">联系电话</label>
          <input type="Number" id="phone" placeholder="请输入联系电话" v-model="formData.phone" :maxlength="11">
          <div class="warn">联系电话不能为空或格式不正确（请输入11位数字）</div>
        </div>
        <div :class="['row', {'anomaly': verify && formData.companyName === ''}]">
          <label for="enterprise" class="must">企业名称</label>
          <input type="text" id="enterprise" placeholder="请输入企业名称" v-model="formData.companyName" :maxlength="20">
          <div class="warn">企业名称不能为空</div>
        </div>
        <div class="row">
          <h4>请选择所需的求职或招聘服务需求</h4>
        </div>
        <div class="row optionWr">
          <div :class="['box', {'anomaly': verify && !formData.positionLevel}]">
            <h4 class="must">岗位级别</h4>
            <ul>
              <li v-for="item in rankArr" :key="item.typeId" :class="{'on': formData.positionLevel == item.typeId}" @click="rankSelect(item.typeId)">
                <div class="p">{{item.typeName}}</div>
                <div class="btn"></div>
              </li>
            </ul>
            <div class="warn">岗位级别不能为空</div>
            <van-loading vertical  type="spinner" color="#1989fa" v-show="listLoad">
              加载中...
            </van-loading>
          </div>
          
          <div :class="['box', {'anomaly': verify && !formData.professionalCategory}]">
            <h4 class="must">专业类别</h4>
            <ul>
              <li v-for="item in typeArr" :key="item.typeId" :class="{'on': formData.professionalCategory == item.typeId}" @click="typeSelect(item.typeId)">
                <div class="p">{{item.typeName}}</div>
                <div class="btn"></div>
              </li>
            </ul>
            <div class="warn">专业类别不能为空</div>
            <van-loading vertical  type="spinner" color="#1989fa" v-show="listLoad">
              加载中...
            </van-loading>
          </div>
        </div>
        <div class="row">
          <label for="remark">备注说明</label>
          <textarea id="remark" placeholder="请输入说明内容" v-model="formData.remarks"></textarea>
        </div>
      </div>
      <div class="operationBox">
        <van-button class="prev" type="primary" size="large" color="#f70000" @click="onClickLeft">取消</van-button>
        <van-button class="submit" type="primary" size="large" color="#f70000" @click="submit">确认提交</van-button>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { usePublic } from '@/api/public'
import { getPositionLevel, getProfessionalCategory, addBuildingSub } from '@/api/http';
import { ref, reactive, watch, onDeactivated } from 'vue';
import { showFailToast, showSuccessToast, showLoadingToast, closeToast } from 'vant';
const { skipLink } = usePublic();
const rankArr = ref([]);
const typeArr = ref([]);
const listLoad = ref(false);
const verify = ref(false);
const phoneVerify = ref(false);
const formData = reactive({
  companyName: "",
  name: "",
  phone: "",
  positionLevel: 0,
  professionalCategory: 0,
  remarks: ""
})
const IntTime = null;
// 电话号码正则表达式
const phoneRegex = /^1[3-9]\d{9}$/;

const getOptions = async () => {
  listLoad.value = true;
  const [ {data: optionList1}, {data: optionList2} ] = await Promise.all([
    getPositionLevel(),
    getProfessionalCategory()
  ])
  rankArr.value = optionList1.data;
  typeArr.value = optionList2.data;
  listLoad.value = false;
}
getOptions();
const onClickLeft = () => history.back();

watch(() => formData.phone, (newVal, oldVal) => {
  if(!verify.value) return
  phoneVerify.value = phoneRegex.test(newVal)
})

onDeactivated(() => {
  clearInterval(IntTime);
})

// 岗位级别选择
const rankSelect = num => formData.positionLevel = num;

// 专业类别选择
const typeSelect = num => formData.professionalCategory = num;

// 提交表单
const submit = async () => {
  verify.value = true;
  phoneVerify.value = phoneRegex.test(formData.phone); 
  if(formData.name == '' || !phoneVerify.value || formData.companyName == '' || !formData.positionLevel || !formData.professionalCategory) return
  showLoadingToast({
    message: '提交中...',
    duration: 0, // 持续显示，直到手动关闭  
    forbidClick: true,
  });
  const { data } = await addBuildingSub(formData);
  closeToast();
  if(data.code == 200){
    skipLink('/successfulDelivery')
  }else{
    showFailToast('提交失败');
  }
}


</script>

<style scoped lang="scss">
.wrap{
  background: #f3f3f3;
}
main{
  padding: .6rem .4rem;
  background: url(~@/assets/images/enterprise/consultBg.jpg) no-repeat top;
  background-size: 100%;
}
.form{
  padding-bottom: .8rem;
  background: #fff;
  border-radius: var(--border-radius-s);
  *::placeholder{
    color: #b7b7b7;
  }
  .row{
    position: relative;
    padding: .45rem .4rem;
    border-bottom: .01rem solid #f1f1f1;
    &:after{
      content: "";
      display: block;
      height: 0;
      clear: both;
    }
    label, h4{
      display: block;
      font-size: .26rem;
      color: #000;
      line-height: .36rem;
      margin-bottom: .06rem;
      &.must:after{
        content: "*";
        display: inline;
        color: #f70000;
      }
    }
    &.anomaly{
      .warn{
        display: block;
      }
    }
    .box{
      position: relative;
      .warn{
        left: 0;
        bottom: -.2rem;
      }
      &.anomaly{
        .warn{
          display: block;
        }
      }
    }
    .warn{
      display: none;
      position: absolute;
      left: .4rem;
      bottom: .05rem;
      font-size: .2rem;
      line-height: .3rem;
      color: #f70000;
    }
    input{
      border: 0 none;
      display: block;
      width: 100%;
      height: .6rem;
      padding: .1rem 0;
      line-height: .4rem;
      font-size: .22rem;
      color: #b7b7b7;
      margin-bottom: -.14rem;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: .4rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
    li{
      position: relative;
      height: .4rem;
      line-height: .4rem;
      width: 2.4rem;
      margin: 0 0 .2rem;
      // &:nth-child(3n + 2){
      //   margin: 0 auto .2rem;
      // }
      // &:nth-child(3n){
      //   width: 2rem;
      // }
      // &.last{
      //   width: 2.26rem;
      //   margin: 0 0 0 .35rem;
      // }
      &.on{
        .p{
          color: #000;
        }
        .btn{
          background: #3a8dea url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAABGUlEQVQ4jbWUMUsDMRiG36vgZKF2cBIXl+4tjjoVXER/gGt/iSB069C5UCgF544V2yKI4KKbqMXFzUFwrfDIlSseIcnl5PpCAvm+N3lIviQRoDVoIulHUnO5dAwpsJWAW/50A0RF7mRD0p2kAyP+VBRkS9JUUt2S+y4VBHhwAD4k1dL1OAFaOeuzDTxj1xuwszypxHyWsl0GAqrA3AF4BSorb9ydW0wXGYA94N0BeATKab8cxlhtD+DTA9g057h2slLHmLDvAdzbAK6amOomnl3gy+GZ+Y43PTj1gPqeGlxnXRIzcOwB2TQOuYm2YDMQMAp9T67EUQbgKs+j9SUPgYUFMMz7a2cZ6gZokBcQAolbA3gBev8BAPoF5GOmt3z4HUMAAAAASUVORK5CYII=) no-repeat center;
          background-size: .25rem;
          border-color: #3a8dea;
        }
      }
      .btn{
        position: absolute;
        right: 0;
        top: 0;
        width: .4rem;
        height: .4rem;
        border: .02rem solid #d1d2d4;
        border-radius: var(--border-radius-s);
        transition: all .3s;
        background: transparent no-repeat center;
      }
      .p{
        font-size: .22rem;
        color: #b7b7b7;
      }
    }
  }
  .optionWr{
    h4{
      margin-bottom: .1rem;
    }
  }
  textarea{
    display: block;
    width: 100%;
    min-height: .6rem;
    border: 0 none;
    padding: .1rem 0;
    font-size: .22rem;
    line-height: .4rem;
  }
}
.operationBox{
  margin-top: .6rem;
  display: flex;
  justify-content: space-between;
  .van-button{
    width: 3.25rem;
  }
}
</style>

