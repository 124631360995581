<template>
  <div class="wrap">
    <nav-ber :title="'专业知识'"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <CinfoUl :list="apiData1"></CinfoUl>
      <Clist :list="apiData2"></Clist>
      <div class="course">
        <h3 class="cTil"><em>学习流程</em></h3>
        <ul>
          <li>
            <div class="icon"><img src="~@/assets/images/knowledge/knowledgeCourseIcon1.jpg" alt=""></div>
            <p>注册流程</p>
          </li>
          <li>
            <div class="icon"><img src="~@/assets/images/knowledge/knowledgeCourseIcon2.jpg" alt=""></div>
            <p>选择课程</p>
          </li>
          <li>
            <div class="icon"><img src="~@/assets/images/knowledge/knowledgeCourseIcon3.jpg" alt=""></div>
            <p>支付缴费</p>
          </li>
          <li>
            <div class="icon"><img src="~@/assets/images/knowledge/knowledgeCourseIcon4.jpg" alt=""></div>
            <p>课程学习</p>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import CinfoUl from "@/components/CinfoUl.vue";
import Clist from "@/components/Clist.vue";
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from '@/api/public';
import { getInfo } from "@/api/http";
import { ref } from "vue";

const apiData0 = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 8 }).then(res => res.data),
    getInfo({ coId: 1053 }).then(res => res.data),
    getInfo({ coId: 365, typeId: 8 }).then(res => res.data),
  ];
  const results = await Promise.all(promises);
  apiData0.value = results[0].data;
  apiData1.value = results[1].data;
  apiData2.value = results[2].data;
};
getList();
</script>

<style scoped lang="scss">
:deep(.cInfoUl){
  .li:nth-child(3){
    .p1 .num{
      font-size: .35rem;
    }
  }
}
.wrap {
  padding-bottom: 0;
}
main {
  padding: .6rem .4rem;
  background: url(~@/assets/images/knowledge/KnowledgeBg.jpg) no-repeat top center #f7f7f7;
  background-size: 100%;
}

:deep(.banner) {
  position: relative;
  overflow: hidden;
  border-radius: .28rem;
  margin-bottom: .6rem;
  .swiper-pagination {
    height: .1rem;
    line-height: .1rem;
    bottom: 0.3rem;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .1rem;
    font-size: 0;
    .swiper-pagination-bullet {
      vertical-align: top;
      margin: 0 .04rem;
      width: .1rem;
      height: 100%;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.3s;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #f70000;
    }
  }
  .txt{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding: .3rem;
    transform: translateY(-50%);
    h3{
      font-size: .38rem;
      line-height: .48rem;
      font-weight: 600;
      letter-spacing: .04rem;
      margin-bottom: .2rem;
      background: linear-gradient(to bottom, #fff, #d9d9d9); 
      -webkit-background-clip: text;  
      -webkit-text-fill-color: transparent; 
    }
    h4{
      font-size: .22rem;
      line-height: .24rem;
      color: rgba(255,255,255,.4);
      font-family: "Gilroy-Bold";
      text-transform: uppercase;
    }
  }
}

.infoUl{
  margin-bottom: .4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .li{
    width: 2.1rem;
    height: 1.64rem;
    padding: .16rem .1rem;
    margin: 0 0 .2rem;
    border-radius: .2rem;
    background: #fff;
    border-radius: 0 0 .5rem rgba(230,230,230,.6);
    &:nth-child(3){
      .unit{
        position: absolute;
        right: 0;
        top: -.2rem;
      }
    }
    .icon{
      width: .5rem;
      margin: 0 auto;
    }
    .p1{
      position: relative;
      text-align: center;
      .num{
        display: inline-block;
        vertical-align: middle;
        font-size: .4rem;
        color: #333;
        line-height: 1;
        font-family: "Gilroy-Bold";
      }
      .unit{
        display: inline-block;
        vertical-align: middle;
        font-size: .22rem;
        color: #999;
        line-height: .3rem;
        margin-left: .06rem;
      }
    }
    .p2{
      margin-top: .04rem;
      text-align: center;
      font-size: .2rem;
      color: #666;
      line-height: .3rem;
    }
  }
}
.product{
  margin-bottom: .6rem;
  .cTil{
    margin-bottom: .6rem;
  }
  .dl{
    overflow: hidden;
    border-radius: .2rem;
    margin-bottom: .3rem;
    .dt{
      position: relative;
      .btn{
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -.35rem 0 0 -.35rem;
        width: .7rem;
        height: .7rem;
        background: url(~@/assets/images/knowledge/iconPlay.png) no-repeat center;
        background-size: contain;
        z-index: 5;
      }
    }
    .dd{
      padding: .25rem .3rem;
      background: #fff;
      h4{
        position: relative;
        font-size: .28rem;
        line-height: 1.4;
        color: #333;
      }
      p{
        font-size: .22rem;
        color: #666;
        line-height: .32rem;
      }
    }
  }
  .li {
    padding: 0.2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 0.3rem;
    .pic {
      position: relative;
      overflow: hidden;
      border-radius: 0.24rem 0.24rem 0 0;
      .num {
        position: absolute;
        right: 0.2rem;
        bottom: 0.16rem;
        font-size: 0.76rem;
        color: #fff;
        font-family: "Gilroy-Bold";
        line-height: 1;
      }
    }
    .txt {
      overflow: hidden;
      min-height: 3.2rem;
      padding: 0.5rem 0.3rem;
      border-radius: 0 0 0.24rem 0.24rem;
      background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
      h4 {
        font-size: 0.28rem;
        color: #000;
        line-break: 0.38rem;
      }
      .line {
        display: none;
        width: 1.06rem;
        height: 0.06rem;
        margin: 0.13rem 0 0.15rem;
        background: #e1e1e1;
        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 0.14rem;
          background: #f70000;
        }
      }
      .btn{
        float: left;
        margin-top: .3rem;
        height: .54rem;
        line-height: .54rem;
        padding: 0 .24rem;
        background: #f70000;
        border-radius: .1rem;
        font-size: 0;
        em{
          font-size: .22rem;
          color: #fff;
          padding-right: .22rem;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC) no-repeat right center;
          background-size: .08rem;
        }
      }
    }
  }
}
.course{
  .cTil{
    margin-bottom: .6rem;
  }
  ul{
    display: flex;
    justify-content: space-between;
  }
  li{
    position: relative;
    &:last-child:after{
      display: none;
    }
    &:after{
      position: absolute;
      right: -.65rem;
      top: 0;
      content: "";
      display: block;
      width: .4rem;
      height: 1rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAYAAAByrA+0AAABZklEQVQ4jYWSy0rDYBSEp0XxupEuXFQtpXXjQkzTiiCWLH0JH8BHcO87uQ11IxSVxguCKSgSRGpxI+gmZ+T8uaht2swqgfnODOc/BZIYtmtrAN5Lnf43clQctmvHAF4APAzbtXouAMCOvysA3DxIgVMAQfxfzoOKpU7/CYAzCg0OqpmQJqDU6fsJpEsIGZaF4r7ur49BZkuJdCpJVyAKIJQwEIpTuXjzMwGVTtXpcYqmBSLibHY//ExA9by3Wg8ZusIoSSgmaevy088EVI+7KyZpBLImAqp7e/lQKGcKEFToaCJwZy+ZBJJRAkQ3aM1kmW8bi3WCaR2SAUHH7oWDMUDNSfe4hjE3e2K29A+4aSz8mql2ag2n6Un6DingWfNRZ0SdE3PLY2pOgZ41F71wXCPpPGpWFa53Zqskz/+a9a6yzCZBKCe6uqTzNLOqKJSruLM582lmU0knd7cLG3qsLY9f08wA8AMmkj4UBcTVRwAAAABJRU5ErkJggg==) no-repeat center;
      background-size: .12rem;
    }
    .icon{
      width: 1rem;
      height: 1rem;
      background: #fff;
      border-radius: 50%;
      overflow: hidden;
      border: .01rem solid #e2e2e2;
      margin: 0 auto .1rem;
    }
    p{
      font-size: .26rem;
      color: #333;
      line-height: .5rem;
    }
  }
}
</style>

