<template>
  <div class="wrap">
    <nav-ber :title="' 项目质量管理'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>项目质量管理</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>质量检查</h4>
                <div class="line"></div>
                <p>支持公司质量标准化体系，制定质量检查工作流程，覆盖现场检查场景，形成检查记录，为工程质量控制提供了数据，提高工作效率和管理水平。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>现场实测实量</h4>
                <div class="line"></div>
                <p>对现场进行实测实量，并与平面图点位进行关联绑定，建立实际测量台账，拍照留痕，把控工程质量。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>质量验收</h4>
                <div class="line"></div>
                <p>制定质量验收计划，验收过程反馈，如有不合格项，可直接发起质量问题整改流程，形成业务闭环；对质量进行分析和处理，生成各种统计图表和报告，帮助用户快速了解工地现场的质量状况。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>质量资料管理</h4>
                <div class="line"></div>
                <p>上传各类工地质量资料，如验收报告、检测数据、质量控制计划等。可以根据不同的项目和文件类型进行分类和整理，方便后续的检索和查阅。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>质量控制-标养室温湿度监测</h4>
                <div class="line"></div>
                <p>实时监测和管理工地标养室内温度和湿度，随时了解标养室的环境状况。当温度或湿度超出预设范围时，系统将自动发出警报通知，以便及时采取相应的措施来维持良好的标养条件，并提供历史查询和检索功能。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/qualityPic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>质量分析</h4>
                <div class="line"></div>
                <p>制定质量验收计划，验收过程反馈，如有不合格项，可直接发起质量问题整改流程，形成业务闭环；对质量进行分析和处理，生成各种统计图表和报告，帮助用户快速了解工地现场的质量状况。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

