<template>
  <div class="wrap">
    <nav-ber :title="'立即咨询'"></nav-ber>
    <main>
      <form class="form">
        <div class="ipt">
          <label class="must">咨询板块</label>
          <div class="p">{{ plateName }}</div>
        </div>
        <div class="ipt">
          <label for="name" class="must">申请人</label>
          <input type="text" id="name" placeholder="请输入经办人姓名"/>
        </div>
        <div class="ipt">
          <label for="phone" class="must">联系电话</label>
          <input type="text" id="phone" placeholder="请输入联系电话"/>
        </div>
        <div class="ipt">
          <label for="company" class="must">企业名称</label>
          <input type="text" id="company" placeholder="请输入企业名称"/>
        </div>
        <div class="textarea">
          <label for="remark" class="must">备注</label>
          <textarea id="remark" placeholder="请输入..."></textarea>
        </div>
        <van-button class="submit" type="primary" size="large" color="#f70000" @click="skipLink('/financial/transactResult')">提交意向</van-button>
      </form>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { usePublic } from '@/api/public';
import { ref, reactive } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const {skipLink} = usePublic();
const showPicker = ref(false);
const letter = ref('请选择出函机构');
const selected = ref(false);
const formData = reactive({
  name: "",
  phone: "",
  plateName: "",
  remarks: ""
})

const plateName = ref('');
let plateStr = route.query.plateArr.split(',').join(' - ');
formData.plateName = plateStr;
plateName.value = plateStr;

const onConfirm = ({selectedOptions}) => {
  letter.value = selectedOptions[0].text;
  showPicker.value = false;
  selected.value = true;
};
</script>

<style scoped lang="scss">
main{
  padding: .7rem .4rem;
  background: #fff url(~@/assets/images/news/newsBg.jpg) no-repeat top center;
  background-size: 100%;
}
.form{
  .hint{
    font-size: .24rem;
    color: #646464;
    line-height: .4rem;
    text-align: center;
    margin-bottom: .42rem;
    em{
      color: #f70000;
    }
  }
  .ipt{
    display: flex;
    margin-bottom: .2rem;
    height: 1.2rem;
    padding: .3rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    label{
      font-size: .26rem;
      color: #000;
      line-height: .6rem;
      margin-right: .3rem;
      &.must:after{
        content: "*";
        color: #f70000;
        font-size: .26rem;
        line-height: .6rem;
      }
    }
    input{
      flex: 1;
      border: 0 none;
      font-size: .22rem;
      color: #333;
      text-align: right;
      &::placeholder{
        color: #b7b7b7;
      }
    }
    span{
      margin-left: auto;
      font-size: .22rem;
      color: #b7b7b7;
      line-height: .6rem;
      padding-right: .18rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAUElEQVQokYXSMQ4AIAgDwOJX+f8bdDAmFVvsSC6UgcjMiZ2AyaDx7FD84NnUQq6zkJGFFUmo0AMduuIQHx4KXUDd9ICKJGBkwUEtqHX6VQAsLewPSU/aZZkAAAAASUVORK5CYII=) no-repeat right center;
      background-size: .09rem;
      &.on{
        color: #333;
      }
    }
  }
}
.van-button{
  margin-top: .4rem;
  height: 1.2rem;
  font-size: .28rem;
  border-radius: var(--border-radius);
}
.textarea{
  position: relative;
  label{
    position: absolute;
    left: .3rem;
    top: .3rem;
    font-size: .26rem;
    color: #000;
    line-height: .6rem;
    margin-right: .3rem;
    &.must:after{
      content: "*";
      color: #f70000;
      font-size: .26rem;
      line-height: .6rem;
    }
  }
  textarea{
    display: block;
    width: 100%;
    border: 0 none;
    height: 3.4rem;
    padding: .87rem .3rem 0;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    font-size: .22rem;
    color: #333;
    line-height: .36rem;
    &::placeholder{
      color: #b7b7b7;
    }
  }
}
</style>

