<template>
  <div class="wrap">
    <nav-ber :title="'项目物料管理'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <van-tabs v-model:active="active" class="big">
            <van-tab title="物料管理"></van-tab>
            <van-tab title="企业钢筋数字化管理"></van-tab>
          </van-tabs>
          <div class="bd">
            <div class="item" v-show="active === 0">
              <div class="narrate">
                <h4>企业钢筋数字化管理</h4>
                <div class="line"></div>
                <p>以“数字化治理+合规化市场管理+落实服务效果“为建设思路，针对安责险业务流程及配套事故预防技术服务管理过程中的需求，平台开设多端信息化管理版块，打破行业数据孤岛、完成数据互联。同时以企业安全生产风险管理为基本要素，延展效能助力各方共同推动安责险的风险数据量化和服务模式创新，增强服务能力。</p>
              </div>
              <h3 class="cTil"><em>产品使用场景</em></h3>
              <div class="ul2">
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic1.jpg" alt="" />
                  </div>
                  <div class="txt">
                    <h4>公司级管理平台</h4>
                    <div class="line"></div>
                    <h5>公司分管领导</h5>
                    <p>实时查看公司各项目钢筋管理情况，及时发现问题项目。</p>
                    <br/>
                    <h5>钢筋专项负责人</h5>
                    <p>设定公司管理要求，维护公司技术方案库;关注各项目指标查看各项目管理人员工作完成情况。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic2.jpg" alt="" />
                  </div>
                  <div class="txt">
                    <h4>项目级应用平台</h4>
                    <div class="line"></div>
                    <h5>现场钢筋管理项类工程师</h5>
                    <p>完成各项钢筋现场管理工作;通过平台记录并汇总分析各项数据,为决策提供依据。</p>
                    <h5>劳务翻样</h5>
                    <p>提交料单、处理驳回料单、申领措施筋等。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic3.jpg" alt="" />
                  </div>
                  <div class="txt">
                    <h4>管理小程序</h4>
                    <div class="line"></div>
                    <h5>现场钢筋管理工程师</h5>
                    <p>通过钢筋智能点根、料单速查、随手拍水印相机等亮点功能,辅助完成现场管理各项工作。</p>
                  </div>
                </router-link>
              </div>
              <h3 class="cTil"><em>产品使用场景</em></h3>
              <div class="ul">
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic4.jpg" alt="" />
                    <div class="num">01</div>
                  </div>
                  <div class="txt">
                    <h4>合同分析</h4>
                    <div class="line"></div>
                    <p>对比系统内置的合同风险项，查找甲方合同是否出现类似内容，设置后平台将自司计算合同亏量百分比，让用户预先对合同亏量心里有数;</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic5.jpg" alt="" />
                    <div class="num">02</div>
                  </div>
                  <div class="txt">
                    <h4>图纸会审</h4>
                    <div class="line"></div>
                    <p>录入图纸会审的文字和图片后，平台可自动排版，智能分页，大幅提升做签字确认单的效率，图纸问题可按需在翻样环节设置提醒，确保劳务人员知晓最新图纸问题细节。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic6.jpg" alt="" />
                    <div class="num">03</div>
                  </div>
                  <div class="txt">
                    <h4>前期策划</h4>
                    <div class="line"></div>
                    <p>在平台中制定策划和翻样要求，形成一个个提示卡，后续环节会按需自动提示。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic7.jpg" alt="" />
                    <div class="num">04</div>
                  </div>
                  <div class="txt">
                    <h4>提交料单</h4>
                    <div class="line"></div>
                    <p>平台智能识别 PDF 格式电子料单，劳务翻样上传时平台会弹出对应提示卡要求其自检确认，按技术要求和策划翻样。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic8.jpg" alt="" />
                    <div class="num">05</div>
                  </div>
                  <div class="txt">
                    <h4>需用计划</h4>
                    <div class="line"></div>
                    <p>需用计划通过“预算对比库存核检'总量校核”，真正做到按需供料、减少库存积压。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic9.jpg" alt="" />
                    <div class="num">06</div>
                  </div>
                  <div class="txt">
                    <h4>进场验收</h4>
                    <div class="line"></div>
                    <p>原材挂牌，扫码使用，盘点更方便，实时获取库存数据。(需选配设备)<br/>
                    AI点根辅助验收，小程序现场拍照记录数据，平台同步，把好进场关，不在源头亏量。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic10.jpg" alt="" />
                    <div class="num">07</div>
                  </div>
                  <div class="txt">
                    <h4>钢筋绑扎</h4>
                    <div class="line"></div>
                    <p>审核后的料单带有水印和二维码，加工绑扎完钢筋，由带班用微信扫码确认，获取实时进度和钢筋生产量。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic11.jpg" alt="" />
                    <div class="num">08</div>
                  </div>
                  <div class="txt">
                    <h4>现场盘点</h4>
                    <div class="line"></div>
                    <p>只需清点库存钢筋，即可完成现场盘点工作，并可记录现场照片作对照，数据对不上自动提示可能情况，确保每月数据准确性。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesNumberPic12.jpg" alt="" />
                    <div class="num">09</div>
                  </div>
                  <div class="txt">
                    <h4>月度分析</h4>
                    <div class="line"></div>
                    <p>根据原材管理及钢筋消耗数据，平台自动生成月度数据分析包括钢筋盈亏、钢筋损耗、措施筋使用分析、库存分析等。</p>
                  </div>
                </router-link>
                <div class="ul3">
                  <router-link to="" class="li">
                    <div class="pic">
                      <img src="~@/assets/images/engineering/suppliesNumberPic13.jpg" alt="" />
                    </div>
                    <div class="txt">
                      <div class="til">
                        <div class="icon"><img src="~@/assets/images/engineering/suppliesListIcon1.jpg" alt=""></div>
                        <h4>企业级管理平台</h4>
                      </div>
                      <p>多维度统览各项目钢筋管理情况<br/>
                      设定各项目钢筋管理要求和规则<br/>
                      维护公司级策划库、管理方案库为项目提供技术资源</p>
                    </div>
                  </router-link>
                  <router-link to="" class="li">
                    <div class="pic">
                      <img src="~@/assets/images/engineering/suppliesNumberPic14.jpg" alt="" />
                    </div>
                    <div class="txt">
                      <div class="til">
                        <div class="icon"><img src="~@/assets/images/engineering/suppliesListIcon2.jpg" alt=""></div>
                        <h4>项目级应用平台</h4>
                      </div>
                      <p>功能全面覆盖钢筋精细化管理各项工作<br/>
                      所有数据自动汇集分析，所有报表自动生成<br/>
                      完善的溯源和留痕设定，确保数据真实反映管理情况</p>
                    </div>
                  </router-link>
                  <router-link to="" class="li">
                    <div class="pic">
                      <img src="~@/assets/images/engineering/suppliesNumberPic15.jpg" alt="" />
                    </div>
                    <div class="txt">
                      <div class="til">
                        <div class="icon"><img src="~@/assets/images/engineering/suppliesListIcon3.jpg" alt=""></div>
                        <h4>钢筋管理小程序</h4>
                      </div>
                      <p>所有在现场完成的工作均有配套小程序，无需二次录入数据<br/>
                      包含AI点根、料单速查等功能，大幅提升工作效率<br/>
                      小程序可接收公司指派管理任务:需按时消项完成</p>
                    </div>
                  </router-link>
                </div>
              </div>
              <router-link class="cConsult" :to="{path:'/consultform', query:{'plateArr': '项目物料解决方案'}}">立即咨询</router-link>
            </div>
            <div class="item" v-show="active === 1">
              <div class="ul">
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesPic1.jpg" alt="" />
                    <div class="num">01</div>
                  </div>
                  <div class="txt">
                    <h4>采购管理</h4>
                    <div class="line"></div>
                    <p>涵盖了整个采购过程，从询价、比价到合同签订和订单管理，按照预算和计划获取所需的物料和服务。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesPic2.jpg" alt="" />
                    <div class="num">02</div>
                  </div>
                  <div class="txt">
                    <h4>出入库管理</h4>
                    <div class="line"></div>
                    <p>可以实现对物料的分类、流程审批、流程监控等功能，能够区分物料的种类和数量，并根据需要进行调度和管理。同时，系统还支持远程操作和数据可视化，方便管理人员实时了解物料的状态和使用情况，及时进行调整和优化，提高物料管理水平。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesPic3.jpg" alt="" />
                    <div class="num">03</div>
                  </div>
                  <div class="txt">
                    <h4>供应商管理</h4>
                    <div class="line"></div>
                    <p>对工地所需的材料和设备的供应商进行有效管理。该模块可以帮助工地管理者快速地与供应商进行沟通、协作和合作，并实现对供应商的全面管理，包括供应商资料维护、供应商合同管理、供应商评价等功能</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesPic4.jpg" alt="" />
                    <div class="num">04</div>
                  </div>
                  <div class="txt">
                    <h4>物料验收</h4>
                    <div class="line"></div>
                    <p>对物料的实际验收情况进行记录。用户可以记录物料的批次号、数量、外观、尺寸、重量等关键信息，并上传照片或文件作为验收记录的凭证，确保所采购的物料符合规定的标准和质量要求。</p>
                  </div>
                </router-link>
                <router-link to="" class="li">
                  <div class="pic">
                    <img src="~@/assets/images/engineering/suppliesPic5.jpg" alt="" />
                    <div class="num">05</div>
                  </div>
                  <div class="txt">
                    <h4>物料管理</h4>
                    <div class="line"></div>
                    <p>通过智能化的设备和系统实现对物料的全生命周期管理，包括物料的采购、入库、出库、运输、监管等环节。智慧工地物料管理可以帮助建筑企业实现对物料的全面管控，提高物料的利用率和效率，降低物料管理成本，优化供应链管理，提高建筑工程的质量和效益。</p>
                  </div>
                </router-link>
              </div>
              <router-link class="cConsult" :to="{path:'/consultform', query:{'plateArr': '企业钢筋数字化管理'}}">立即咨询</router-link>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
@import "~@/assets/css/vanTabs.scss";
.wrap {
  padding-bottom: 0;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  background: #f8f8f8;
}
.van-tabs {
  margin-bottom: 0.5rem;
}

.li {
  padding: 0.2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 0.3rem;
  .pic {
    position: relative;
    overflow: hidden;
    border-radius: 0.24rem 0.24rem 0 0;
    .num {
      position: absolute;
      right: 0.2rem;
      bottom: 0.16rem;
      font-size: 0.76rem;
      color: #fff;
      font-family: "Gilroy-Bold";
      line-height: 1;
    }
  }
  .txt {
    min-height: 3.2rem;
    padding: 0.5rem 0.3rem;
    border-radius: 0 0 0.24rem 0.24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4 {
      font-size: 0.28rem;
      color: #000;
      line-break: 0.38rem;
    }
    .line {
      width: 1.06rem;
      height: 0.06rem;
      margin: 0.13rem 0 0.15rem;
      background: #e1e1e1;
      &:before {
        content: "";
        display: block;
        height: 100%;
        width: 0.14rem;
        background: #f70000;
      }
    }
  }
}
.narrate{
  margin-bottom: .5rem;
  h4 {
    font-size: 0.28rem;
    color: #000;
    line-break: 0.38rem;
  }
  .line {
    width: 1.06rem;
    height: 0.06rem;
    margin: 0.13rem 0 0.15rem;
    background: #e1e1e1;
    &:before {
      content: "";
      display: block;
      height: 100%;
      width: 0.14rem;
      background: #f70000;
    }
  }
}
.cTil{
  margin-bottom: .5rem;
}
.ul2{
  .li{
    padding: 0;
    box-shadow: none;
    .pic{
      border-radius: .28rem .28rem 0 0;
    }
    .txt{
      padding: .5rem .3rem;
      border: .01rem solid #ddd;
      border-top: 0 none;
      background: #fff;
      h4{
        position: relative;
        font-size: .3rem;
        display: inline-block;
        vertical-align: top;
        padding-bottom: .13rem;
        border-bottom: .06rem solid #e1e1e1;
        margin-bottom: .13rem;
        &:after{
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: -.06rem;
          height: .06rem;
          width: .14rem;
          background: #f70000;
        }
      }
      .line{
        display: none;
      }
      h5 {
        font-size: 0.28rem;
        color: #000;
        line-break: 0.38rem;
      }
      .line {
        width: 1.06rem;
        height: 0.06rem;
        margin: 0.13rem 0 0.15rem;
        background: #e1e1e1;
        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 0.14rem;
          background: #f70000;
        }
      }
    }
  }
}
.ul3{
  .li{
    padding: 0;
    .pic{
      border-radius: var(--border-radius);
      border: 1px solid #ddd;
    }
    .txt{
      padding: .3rem .4rem;
      background: #fff;
      .til{
        display: flex;
        align-items: center;
        margin-bottom: .2rem;
        .icon{
          width: .8rem;
          height: .8rem;
          overflow: hidden;
          border-radius: 50%;
          border: .01rem solid #e4e4e4;
          margin-right: .2rem;
        }
        h4{
          flex: 1;
        }
      }
    }
  }
}
</style>

