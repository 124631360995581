<template>
  <div class="wrap">
    <nav-ber :title="'建筑专业知识'"></nav-ber>
    <main>
      <swiper
        :pagination="pagination"
        :modules="modules"
        :loop="true"
        class="banner"
      >
        <swiper-slide>
          <img src="~@/assets/images/knowledge/architectureBan1.jpg" alt="">
          <div class="txt">
            <h3>建筑专业知识</h3>
            <h4>Architecturalknowledge</h4>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <img src="~@/assets/images/knowledge/architectureBan1.jpg" alt="">
          <div class="txt">
            <h3>建筑专业知识</h3>
            <h4>Architecturalknowledge</h4>
          </div>
        </swiper-slide>
        <swiper-slide>
          <img src="~@/assets/images/knowledge/architectureBan1.jpg" alt="">
          <div class="txt">
            <h3>建筑专业知识</h3>
            <h4>Architecturalknowledge</h4>
          </div>
        </swiper-slide> -->
      </swiper>
      <div class="entrance">
        <div class="le">
          <div class="til">建筑<br>知识库</div>
          <div class="btn" @click="skipOutsideChain('https://m.zhulong.com/vip/')">立即进入</div>
        </div>
        <div class="ri" v-if="!$store.state.iosAudit">
          <div class="til">开通VIP会员</div>
          <p>解锁无限学习之门!加入我们的会员计划，畅享精品课程</p>
          <div class="price"><em>¥</em> 248</div>
          <div class="btn" @click="skipOutsideChain('https://sm390.1py.com.cn/home?lesson_id=110')">立即购买</div>
        </div>
      </div>
      <div class="details">
        <img src="      /hjt-door/202406/gw/hjt/202406071153344d9fccc8-030b-49fa-b929-9e130e3d282b.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/202406071153350d4a97f9-f4fd-4eac-8f46-becbd2e10ef0.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/2024060711532913735632-b4e2-4244-9869-39b4592ff1d9.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/202406071153263f039001-dfe4-485d-aef5-b12d9935ff52.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/2024060711532679509390-4a9f-468b-b582-72e01ba09c72.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/2024060711532655c80bbb-7f92-47ea-8a2e-2fad275e5c48.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/202406071153293a9efb74-af8a-493c-86e7-6de8f57407af.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/2024060711532918ce56c9-f133-45c4-80f1-b6a4b8cdb313.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/202406071153298c482861-5a97-4909-bee3-44b2e43b96ab.png" alt="">
        <img src="      /hjt-door/202406/gw/hjt/20240607115332936defc7-7706-47eb-a118-c1d67c4d02ae.png" alt="">
      </div>
    </main>
  </div>
</template>
<script setup>
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

import { skipOutsideChain } from '@/api/public';
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
// const isIOS = ref(false);
// const userAgent = navigator.userAgent;
// isIOS.value = /iPhone|iPad|iPod/i.test(userAgent);

const modules = [Pagination];
const pagination = {
  clickable: true,
};

</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .6rem .4rem;
  background: url(~@/assets/images/knowledge/KnowledgeBg.jpg) no-repeat top center #f7f7f7;
  background-size: 100%;
}

:deep(.banner) {
  position: relative;
  overflow: hidden;
  border-radius: .28rem;
  margin-bottom: .6rem;
  .swiper-pagination {
    height: .1rem;
    line-height: .1rem;
    bottom: 0.3rem;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .1rem;
    font-size: 0;
    .swiper-pagination-bullet {
      vertical-align: top;
      margin: 0 .04rem;
      width: .1rem;
      height: 100%;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.3s;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #f70000;
    }
  }
  .txt{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding: .3rem;
    transform: translateY(-50%);
    h3{
      font-size: .38rem;
      line-height: .48rem;
      font-weight: 600;
      letter-spacing: .04rem;
      margin-bottom: .2rem;
      background: linear-gradient(to bottom, #fff, #d9d9d9); 
      -webkit-background-clip: text;  
      -webkit-text-fill-color: transparent; 
    }
    h4{
      font-size: .22rem;
      line-height: .24rem;
      color: rgba(255,255,255,.4);
      font-family: "Gilroy-Bold";
      text-transform: uppercase;
    }
  }
}

.entrance{
  display: flex;
  justify-content: space-between;
  margin-bottom: .6rem;
  .le{
    flex: 1;
    padding: .35rem .3rem;
    width: 2.4rem;
    height: 2.3rem;
    background: url(~@/assets/images/knowledge/architectureEntranceLeBg.jpg) no-repeat center;
    background-size: cover;
    border-radius: .2rem;
    .til{
      font-size: .28rem;
      color: #fbf0e6;
      line-height: .36rem;
      font-weight: 600;
    }
    .btn{
      margin-top: .4rem;
      width: 1.16rem;
      height: .48rem;
      line-height: .48rem;
      text-align: center;
      font-size: .2rem;
      color: #ffe3cf;
      background: #8d4222;
      border-radius: .48rem;
    }
  }
  .ri{
    margin-left: .2rem;
    position: relative;
    padding: .35rem .3rem;
    width: 4.1rem;
    height: 2.3rem;
    background: url(~@/assets/images/knowledge/architectureEntranceRiBg.jpg) no-repeat center;
    background-size: cover;
    border-radius: .2rem;
    .til{
      position: relative;
      font-size: .28rem;
      background: linear-gradient(to right, #fef0e0, #d3a287); 
      -webkit-background-clip: text;  
      -webkit-text-fill-color: transparent; 
      line-height: .36rem;
      padding-left: .44rem;
      font-weight: 600;
      background-size: .33rem;
      margin-bottom: .1rem;
      &:after{
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        display: block;
        height: .36rem;
        width: .33rem;
        background: url(~@/assets/images/knowledge/architectureEntranceRiIcon.png) no-repeat left center / 100%; 
      }
    }
    p{
      font-size: .22rem;
      color: #e6d7d1;
      line-height: .3rem;
    }
    .price{
      position: absolute;
      left: .3rem;
      bottom: .2rem;
      font-size: .34rem;
      line-height: .48rem;
      background: linear-gradient(to right, #fef0e0, #d3a287); 
      -webkit-background-clip: text;  
      -webkit-text-fill-color: transparent; 
      font-weight: 600;
      em{font-size: .2rem;}
    }
    .btn{
      position: absolute;
      right: .3rem;
      bottom: .2rem;
      width: 1.16rem;
      height: .48rem;
      line-height: .48rem;
      text-align: center;
      font-size: .2rem;
      color: #1f242e;
      background: linear-gradient(to right, #fef0e0, #d3a287);
      border-radius: .48rem;
    }
  }
}
</style>

