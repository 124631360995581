<template>
  <div class="wrap">
    <nav-ber :title="'数字服务'"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      
      <CinfoUl :list="apiData1"></CinfoUl>
      
      <section class="lump2">
        <h3 class="cTil"><em>应用商城</em></h3>
        <div class="list">
          <div class="li">
            <div class="pic">
              <img :src="`      ${apiData2[0].image}`" alt=""/>
            </div>
            <div class="txt">
              <h4>{{apiData2[0].newsTitle}}</h4>
              <div v-html="apiData2[0].newsContent"></div>
              <div class="btn" @click="yysc"><em>前往专区</em></div>
            </div>
          </div>
        </div>
      </section>
      <section class="lump3">
        <h3 class="cTil"><em>数字化方案</em></h3>
        <div class="box">
          <div class="item">
            <h5>政务数字化</h5>
            <div class="ul">
              <router-link class="li" :to="item.address" v-for="item in apiData3.slice(0,3)" :key="item.newsId">
                <div class="img">
                  <img :src="item.newsAccess"
                    alt=""
                  />
                </div>
                <div class="txt">
                  <h6>{{item.newsTitle}}</h6>
                  <p>{{item.newsContent}}</p>
                </div>
              </router-link>
            </div>
            <router-link to="/digital/governmentdigital" class="a">进入专区</router-link>
          </div>
          <div class="item">
            <h5>企业数字化</h5>
            <div class="ul">
              <router-link class="li" :to="item.address" v-for="item in apiData3.slice(3,6)" :key="item.newsId">
                <div class="img">
                  <img :src="item.newsAccess"
                    alt=""
                  />
                </div>
                <div class="txt">
                  <h6>{{item.newsTitle}}</h6>
                  <p>{{item.newsContent}}</p>
                </div>
              </router-link>
            </div>
            <a href="/digital/enterpriseend" class="a">进入专区</a>
          </div>
        </div>
      </section>
      <section class="lump4">
        <div class="list">
          <div class="li">
            <div class="pic">
              <img :src="`      ${apiData2[1].image}`" alt=""/>
            </div>
            <div class="txt">
              <h4>{{apiData2[1].newsTitle}}</h4>
              <div v-html="apiData2[1].newsContent"></div>
              <router-link class="btn" :to="apiData2[1].address"><em>前往专区</em></router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="lump5">
        <h3 class="cTil"><em>政务大厅</em></h3>
        <div class="list">
          <div class="li">
            <div class="pic">
              <img :src="`      ${apiData2[2].image}`" alt=""/>
            </div>
            <div class="txt">
              <h4>{{apiData2[2].newsTitle}}</h4>
              <div v-html="apiData2[2].newsContent"></div>
              <router-link class="btn" :to="apiData2[2].address"><em>前往专区</em></router-link>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import Cbanner from "@/components/Cbanner.vue";
import CinfoUl from "@/components/CinfoUl.vue";
import { showDialog } from 'vant';
import { getInfo } from "@/api/http";
import { ref } from "vue";
const active = ref(0);
const apiData0 = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 6 }).then(res => res.data),
    getInfo({ coId: 1041 }).then(res => res.data),
    getInfo({ coId: 365, typeId: 6 }).then(res => res.data),
    getInfo({ coId: 1045 }).then(res => res.data)
  ];
  const results = await Promise.all(promises);
  apiData0.value = results[0].data;
  apiData1.value = results[1].data;
  apiData2.value = results[2].data;
  apiData3.value = results[3].data;
  console.log('测试3', apiData3.value)
};
getList();

// 应用商城
let yysc = () => {
  showDialog({
    title: '如需了解，请移步电脑端访问'
  }).then(() => {
    // on close
  });
}
// 敬请期待
function comingSoon(){
  
}
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}

.cTil {
  margin-bottom: 0.6rem;
}

.list {
  .li {
    padding: 0.2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 0.3rem;
    .pic {
      position: relative;
      overflow: hidden;
      border-radius: 0.24rem 0.24rem 0 0;
      height: 3.93rem;
      img {
        height: 100%;
        object-fit: cover;
      }
      .num {
        position: absolute;
        right: 0.2rem;
        bottom: 0.16rem;
        font-size: 0.76rem;
        color: #fff;
        font-family: "Gilroy-Bold";
        line-height: 1;
      }
    }
    .txt {
      overflow: hidden;
      min-height: 2.8rem;
      padding: 0.5rem 0.3rem;
      border-radius: 0 0 0.24rem 0.24rem;
      background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
      h4 {
        font-size: 0.28rem;
        color: #000;
        line-break: 0.38rem;
      }
      .line {
        width: 1.06rem;
        height: 0.06rem;
        margin: 0.13rem 0 0.15rem;
        background: #e1e1e1;
        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 0.14rem;
          background: #f70000;
        }
      }
      .btn {
        float: left;
        margin-top: 0.3rem;
        height: 0.54rem;
        line-height: 0.54rem;
        padding: 0 0.24rem;
        background: #f70000;
        border-radius: 0.1rem;
        font-size: 0;
        em {
          font-size: 0.22rem;
          color: #fff;
          padding-right: 0.22rem;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC)
            no-repeat right center;
          background-size: 0.08rem;
        }
      }
    }
  }
}
.lump3{
  margin-bottom: .6rem;
  .box {
    margin-top: .4rem
  }
  .item {
    background: #fff;
    padding: .4rem;
    margin-top: .3rem;
    h5 {
      color: #333;
      font-size: .32rem;
      line-height: .42rem;
      margin-bottom: .3rem;
    }
    h6 {
      color: #333;
      font-size: .28rem;
      line-height: .38rem;
      margin-bottom: .1rem;
    }
    .a {
      color: #d7000f;
      display: table;
      font-size: .26rem;
      margin-top: .3rem;
      line-height: .5rem;
    }
    p {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis
    }
    .li {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: .2rem;
    }
    .img {
      width: 26.63%
    }
    .txt {
      width: 68.5%
    }
  }
}
.cInfoUl{
  margin-bottom: .6rem;
}
</style>

