<template>
  <div class="wrap">
    <nav-ber :title="'安全体验馆解决方案'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>安全体验馆</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>VR安全体验</h4>
                <div class="line"></div>
                <p>通过佩戴VR头盔进入虚拟现实环境，身临其境地感受工地的各种危险场景，如高空作业、起重机操作、脚手架搭建等；工人可以在虚拟环境中进行反复练习和模拟操作，提高自己的技能水平和安全意识。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>急救体验</h4>
                <div class="line"></div>
                <p>模拟真实场景来提高工人在紧急情况下的应对能力和急救技能的培训方式，参与者可以穿上急救服和手套，模拟各种紧急情况，如心脏骤停、意外伤害等，学习如何正确地进行心肺复苏、止血包扎等基本急救操作</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>安全用电</h4>
                <div class="line"></div>
                <p>电器、开关插座、灯具等用电设备展示及体验，告知相关的安全规定和标准。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>安全帽撞击</h4>
                <div class="line"></div>
                <p>让工人熟知安全帽的重要性及正确佩戴方法，体验佩戴安全帽承受物体打击时的瞬间感受。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>移动操作平台</h4>
                <div class="line"></div>
                <p>标准化的移动操作平台，模拟作业人员在现场移动、固定、攀登的使用了解使用规定。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>灭火体验</h4>
                <div class="line"></div>
                <p>演示发生火灾时如何正确使用消防器材，培训有效的应急处置方法。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/experiencePic7.jpg" alt="" />
                <div class="num">07</div>
              </div>
              <div class="txt">
                <h4>重物搬移</h4>
                <div class="line"></div>
                <p>主要是体验搬重物时掌握身体重心，防止伤及身体。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

