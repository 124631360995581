<template>
  <div class="wrap">
    <nav-ber :title="'智能建造'"></nav-ber>
    <main class="engineering">
      <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>Full-scenario <br>
        solution for the construction <br>
        stage</h5>
      </div>
      <div class="content">
        <div class="aggregation">
          <van-tabs v-model:active="active" class="ball" @click-tab="scrollToSection">
            <van-tab title="全部"></van-tab>
            <van-tab title="智能硬件"></van-tab>
            <van-tab title="安全"></van-tab>
            <van-tab title="质量"></van-tab>
            <van-tab title="绿色施工"></van-tab>
            <van-tab title="BIM"></van-tab>
            <van-tab title="进度"></van-tab>
            <van-tab title="成本"></van-tab>
            <van-tab title="机械"></van-tab>
            <van-tab title="物料"></van-tab>
            <van-tab title="智慧展厅"></van-tab>
            <van-tab title="安全体验馆"></van-tab>
            <van-tab title="智慧印章"></van-tab>
          </van-tabs>
          <div class="bd">
            <router-link data-id="BIM" class="item" to="/engineering/bim">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic1.jpg" alt=""></div>
              <div class="txt">
                <h4>BIM解决方案</h4>
                <p>提供全面的BIM解决方案优化建筑生命周明管</p>
              </div>
            </router-link>
            <router-link data-id="智能硬件" class="item" to="/engineering/intelligent">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic2.jpg" alt=""></div>
              <div class="txt">
                <h4>智能硬件解决方案</h4>
                <p>整合先进的硬件技术和智能化解决方案</p>
              </div>
            </router-link>
            <router-link data-id="安全" class="item" to="/engineering/safety">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic3.jpg" alt=""></div>
              <div class="txt">
                <h4>项目安全解决方案</h4>
                <p>整合先进的安全技术与流程提供安全管理方案</p>
              </div>
            </router-link>
            <router-link data-id="质量" class="item" to="/engineering/quality">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic4.jpg" alt=""></div>
              <div class="txt">
                <h4>项目质量解决方案</h4>
                <p>结合先选的质量管理工具提供质量解决方案</p>
              </div>
            </router-link>
            <router-link data-id="进度" class="item" to="/engineering/schedule">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic5.jpg" alt=""></div>
              <div class="txt">
                <h4>项目进度解决方案</h4>
                <p>提供全面的项目进度解决方案</p>
              </div>
            </router-link>
            <router-link data-id="成本" class="item" to="/engineering/cost">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic6.jpg" alt=""></div>
              <div class="txt">
                <h4>项目成本解决方案</h4>
                <p>提供综合的项目成本解决方案</p>
              </div>
            </router-link>
            <router-link data-id="绿色施工" class="item" to="/engineering/build">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic7.jpg" alt=""></div>
              <div class="txt">
                <h4>绿色施工解决方案</h4>
                <p>以环境和资源节约为核心提供绿色施工解决...</p>
              </div>
            </router-link>
            <router-link data-id="机械" class="item" to="/engineering/machinery">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic8.jpg" alt=""></div>
              <div class="txt">
                <h4>项目机械解决方案</h4>
                <p>提供全面的项目机械管理解决方案</p>
              </div>
            </router-link>
            <router-link data-id="物料" class="item" to="/engineering/supplies">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic9.jpg" alt=""></div>
              <div class="txt">
                <h4>项目物料解决方案</h4>
                <p>提供综合的项目物料管理解决方案</p>
              </div>
            </router-link>
            <router-link data-id="智慧展厅" class="item" to="/engineering/exhibition">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic10.jpg" alt=""></div>
              <div class="txt">
                <h4>智慧展厅解决方案</h4>
                <p>整合先进技术提供创新的智慧展厅解决方案</p>
              </div>
            </router-link>
            <router-link data-id="安全体验馆" class="item" to="/engineering/experience">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic11.jpg" alt=""></div>
              <div class="txt">
                <h4>安全体验馆解决方案</h4>
                <p>提供全面的安全体验馆解决方案</p>
              </div>
            </router-link>
            <router-link data-id="智慧印章" class="item" to="/engineering/seal">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic13.jpg" alt=""></div>
              <div class="txt">
                <h4>智慧印章解决方案</h4>
                <p>在线实时管控的智能印章</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="case">
          <h2 class="til"><em>应用案例</em></h2>
          <div class="item">
            <h3>南宁绿地中央广场</h3>
            <div class="li">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic14.jpg" alt=""></div>
              <div class="txt">
                <h4>应用价值</h4>
                <p>通过智慧工地软硬件―体化建设，轻松掌握工程现场整体趋势，为保障现场项目质量、安全施工创造了有利条件（安全事故0发生、节约安全管理成本15%、安全问题100%销项闭环）</p>
                <router-link to="/engineering/adhibition">查看详情</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="logoList">
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZTJS.jpg" alt=""></div><div class="p">中天建设集团有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团第三建筑工程有限责任公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团控股有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团建筑工程总承包有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建三局第一建设工程有限责任公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建三局第二建设工程有限责任公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第八工程局有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建科工集团有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中建科工集团有限公司</div></div>
          <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoTYJZ.jpg" alt=""></div><div class="p">腾越建筑科技集团</div></div>
          <van-button class="more" v-if="!logoArr" plain hairline type="primary" block color="#9f9f9f" style="height: .9rem; border-color: #e6e6e6; margin-top: .3rem;" @click="logoArr = true">查看更多</van-button>
          <div class="hide" v-else>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoJBJS.jpg" alt=""></div><div class="p">佳邦建设集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoJBJS.jpg" alt=""></div><div class="p">佳邦建设集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工集团第四建筑工程有限责任公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGZT.jpg" alt=""></div><div class="p">中国中铁股份有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">广西建工第一建筑工程集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXJG.jpg" alt=""></div><div class="p">五建集团</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第五工程局</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第七工程局有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoZGJY.jpg" alt=""></div><div class="p">中国建筑第三工程局有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoGXYH.jpg" alt=""></div><div class="p">广西裕华建设集团</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogoBTJT.jpg" alt=""></div><div class="p">广西路桥工程集团有限公司</div></div>
            <div class="li"><div class="logo"><img src="~@/assets/images/engineering/engineeringLogGXHY.jpg" alt=""></div><div class="p">广西华业建筑工程有限公司</div></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { ref, onMounted } from 'vue';
const active = ref(0);
const containerRef = ref(null);
const subsidyHei = ref(0);
const logoArr = ref(false);
onMounted(() => {
  // 获取页面滚动容器（通常是<body>或指定的某个<div>）
  containerRef.value = document.querySelector('#scrollContainer') || document.body;
  const topBoxHei = document.querySelector('.topBox').offsetHeight;
  subsidyHei.value = topBoxHei;
});
function scrollToSection(agms) {
  const targetId = agms.title;
  const targetElement = document.querySelector(`[data-id="${targetId}"]`);

  if (!targetElement) return;
  const scrollTop = targetElement.offsetTop + subsidyHei.value; // 考虑到可能有固定头部，减去头部高度
  window.scrollTo({ top: scrollTop, behavior: 'smooth' });
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.wrap{padding-bottom: 0;}
.topBox{
  height: 3.55rem;
  padding: 1.2rem .4rem 0;
  h2{
    font-size: .38rem;
    color: #fff;
    font-weight: 600;
    line-height: .4rem;
    padding-bottom: .06rem;
  }
  h4{
    font-size: .22rem;
    line-height: .36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: .04rem;
  }
  h5{
    font-size: .24rem;
    color: rgba(229,200,197,.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold';
  }
}
.content{
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-radius: .28rem .28rem 0 0;
  background: #f1f1f1;
}
.aggregation{
  padding: .6rem .4rem;
  background: #fff;
  .van-tabs{
    margin-bottom: .6rem;
  }
  .bd{
    .item{
      overflow: hidden;
      border-radius: .28rem;
      margin-bottom: .3rem;
      background: #e2e2e2;
      box-shadow: 0 .02rem .5rem rgba(230,230,230,.6);
    }
    .txt{
      height: 1.6rem;
      padding: .36rem .4rem 0;
      h4{
        font-size: .28rem;
        color: #000;
        line-height: .44rem;
        margin-bottom: .04rem;
      }
      p{
        font-size: .22rem;
        color: #888a88;
        line-height: .3rem;
      }
    }
  }
}
.case{
  padding: .6rem .4rem 1rem;
  .til{
    display: flex;
    justify-content: center;
    height: .7rem;
    border-bottom: .06rem solid #e1e1e1;
    font-size: .36rem;
    color: #2d322d;
    line-height: .56rem;
    text-align: center;
    em{
      height: .7rem;
      border-bottom: .06rem solid #f70000;
    }
  }
  .item{
    padding: .2rem 0 0;
    h3{
      font-size: .28rem;
      color: #2d322d;
      line-height: .48rem;
      text-align: center;
      margin-bottom: .2rem;
    }
    .li{
      overflow: hidden;
      border-radius: .28rem;
    }
    .txt{
      position: relative;
      height: 3.9rem;
      padding: .5rem .4rem;
      background: url(~@/assets/images/engineering/engineeringCaseItemBg.jpg) no-repeat center;
      background-size: 100% 100%;
      h4{
        font-size: .28rem;
        color: #fff;
        line-height: .4rem;
        margin-bottom: .3rem;
      }
      p{
        font-size: .22rem;
        color: rgba(255,255,255,.9);
        line-height: .36rem;
      }
      a{
        position: absolute;
        right: .4rem;
        bottom: .4rem;
        width: 1.5rem;
        height: .54rem;
        text-align: center;
        color: #fff;
        line-height: .52rem;
        border-radius: .54rem;
        border: .01rem solid rgba(255,255,255,.4);
      }
    }
  }
}
.logoList{
  display: flex;
  flex-wrap: wrap;
  padding: 0 .4rem .4rem;
  justify-content: space-between;
  .li{
    width: 100%;
    height: 1.2rem;
    padding: 0 .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: var(--border-radius);
    margin-bottom: .14rem;
    .logo{
      height: .8rem;
      width: 2.34rem;
      img{
        max-height: 100%;
      }
    }
    .p{
      flex: 1;
      font-size: .22rem;
      color: #5d5d5d;
      line-height: .36rem;
    }
  }
  .more{
    background: transparent;
  }
}
</style>

